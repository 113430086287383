import React from "react";
import ManlyPacificHero from "../../components/manly-pacific-hero";
import ManlyPacificDetails from "../../components/manly-pacific-details";
import Footer from "../../components/shared/footer";
import { useLocation } from "react-router-dom";

const ManlyPacific = () => {
  const {pathname} = useLocation();
  console.log('pathname :>> ', pathname);
  return (
    <>
      <ManlyPacificHero />
      <ManlyPacificDetails />
      <Footer />
    </>
  );
};

export default ManlyPacific;
