import React from "react";
import { Link } from "react-router-dom";

import OwlCarousel from "react-owl-carousel";

import ProjectCard from "./shared/project-card";

import AgedCare from "../assets/images/agedcare3.png";
import Government from "../assets/images/project5-2.jpg";
import Residential from "../assets/images/residential03.png";
import Council from "../assets/images/pro1-1.png";

const ProjectsDirectory = () => {
  return (
    <section className="home-s-5 bg-light">
      <div className="container-fluid">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <h2 className="fs-40 fw-600 color-dark-blue">
                PROJECTS DIRECTORY
              </h2>
            </div>
          </div>
          <div className="desktop-screen">
            <div className="row">
              <OwlCarousel
                className="owl-carousel owl-theme"
                loop
                margin={24}
                dots={false}
                autoplay={true}
                autoplayTimeout={2500}
              >
                <ProjectCard
                  key="1"
                  image={AgedCare}
                  description="Aged Care"
                  location=" "
                />
                <ProjectCard
                  key="2"
                  image={Government}
                  description="Government"
                  location=" "
                />
                <ProjectCard
                  key="3"
                  image={Residential}
                  description="Residential"
                  location=" "
                />
                <ProjectCard
                  key="4"
                  image={Council}
                  description="Council"
                  location=" "
                />
              </OwlCarousel>
            </div>
          </div>
          <div className="tablet-s">
            <div className="row">
              <OwlCarousel
                className="owl-carousel owl-theme"
                loop
                items={1}
                margin={24}
                dots={false}
                autoplay={true}
                autoplayTimeout={2500}
              >
                <ProjectCard
                  key="1"
                  image={AgedCare}
                  description="Aged Care"
                  location=" "
                />
                <ProjectCard
                  key="2"
                  image={Government}
                  description="Government"
                  location=" "
                />
                <ProjectCard
                  key="3"
                  image={Residential}
                  description="Residential"
                  location=" "
                />
                <ProjectCard
                  key="4"
                  image={Council}
                  description="Council"
                  location=" "
                />
              </OwlCarousel>
            </div>
          </div>
          <div className="d-flex flex-row justify-content-center w-100 mt-5">
            <Link
              to="/projects-listing"
              className="yellow-btn"
              style={{ textDecoration: "none", color: "black" }}
            >
              SEE PROJECT LISTING
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsDirectory;
