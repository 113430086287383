import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/basementWaterproof/1.webp";
import ProjectImage2 from "../assets/images/basementWaterproof/2.webp";

const BasementWaterproovingDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Job Duration:</b> &nbsp;
                  9 weeks
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Challenges Overcome </b> &nbsp; Basement water ingress and outdoor area revitalisation                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Value</b> &nbsp; $260,000
                </p>
                {/* <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp;
                  Renovation and refurbishment of the Premium Function Room and
                  Roof top pool and surrounds. Elliam’s experienced project
                  management team allowed the hotel to complete a successful
                  construction with minimum interruption to guest services.
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Activities</b> &nbsp; Activities
                  include the demolition of the roof top pool, waterproofing in
                  and around the pool, balustrades instal and pool
                  refurbishment. An additional project was the refurbishment of
                  the Premium Function Room.
                </p> */}
                {/* <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br /> */}
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                  Watertight Basement: Ellaim’s team successfully addressed the chronic issue of water ingress through the basement of a block of units. Through the implementation of advanced sheet waterproofing, meticulously welded together to eliminate potential leaks, we delivered a basement that is now resilient to water damage. This project both enhanced the functionality of the space and safeguards it against future water-related issues.​
                  </li>
                  <li className="fs-17">
                  Outdoor Area Transformation: In addition to waterproofing the basement, we undertook a comprehensive transformation of the outdoor area. By removing existing tiles, installing new stormwater solutions, and introducing contemporary tiling, we revitalised the outdoor space. The result is an aesthetically pleasing, functional, and weather-resistant area that adds value to the entire property.
                  </li>
                </ul>
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h4 className="fw-600">System</h4>
                <br />
                <ul>
                  <li className="fs-17">
                  Efficient Project Management System: Throughout the 9-week project, our sophisticated project management system facilitated the timely completion of the project and also prioritised worker and resident safety. The low defect rate is a result of our meticulous planning and execution. Photo documentation of the construction process provides transparency and showcases our commitment to quality.
                  </li>               
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <ul>
                  <li className="fs-17">
                  The construction involved the careful transport of materials within the lift and  rigorous safety measures to safeguard both workers and residents, ensuring a secure work environment.
                  </li>
                </ul>
                <br />
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <ul>
                  <li className="fs-17">
                  Our commitment to sustainability extended to the outdoor area transformation. From responsible waste disposal to the use of environmentally friendly materials, we prioritized eco-conscious choices. The result is an outdoor space that not only meets aesthetic and functional needs but also aligns with our dedication to environmental responsibility.
                  </li>
                  <li className="fs-17">
                  Beyond addressing immediate issues, our construction practices incorporated sustainable techniques. From material selection to outdoor landscaping, our team prioritized environmentally friendly options, showcasing our dedication to fostering sustainable and resilient living spaces.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default BasementWaterproovingDetails;
