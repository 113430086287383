import React from "react";
import { Helmet } from "react-helmet-async";

import News4DetailHero from "../components/news4-detail-hero";
import News4ContentDetails from "../components/news4-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/news4-01.jpeg";

const NewsDetails = () => {
  return (
    <>
    <header>
      <Helmet>
        <meta property="og:title" content={data.news[3].title} />
        <meta property="og:description" content="As environmental regulations and growing awareness drive green building trends, Elliam is at the forefront of shaping a greener future for construction. Discover how our innovative green building practices, waste reduction strategies, and a commitment to healthier indoor spaces are transforming the construction industry. Partner with Elliam for a sustainable and health-conscious approach to construction." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-04" />
      </Helmet>
      <News4DetailHero />
      </header>
      <News4ContentDetails />
      <Footer />
    </>
  );
};

export default NewsDetails;
