import React, { useState } from "react";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo.svg";

const Header = () => {
  const [show, setShow] = useState(false);

  const handleNavShow = () => {
    setShow(!show);
  };
  const featuredLinks = [
    { id: "housing-department", title: "Department of Housing" },
    { id: "school-department", title: "Department of Schools" },
    { id: "manly-pacific", title: "Manly Pacific" },
    { id: "southern-cross-care", title: "Southern Cross Care" },
    { id: "uniting-care", title: "Uniting Care" },
    { id: "willoughby-council", title: "Willoughby Council" },
    { id: "rockdale-strata", title: "Rockdale Strata" },
    { id: "merryland’s-library-recladding", title: "Merryland's Library" },
  ];
  return (
    <>
      <div className="container sticky-top pt-3 desktop-nav">
        <nav className="navbar  navbar-expand-lg navbar-light bg-light p-0">
          <div className="container-fluid px-0">
            <Link className="navbar-brand px-4" to="/">
              <img src={logo} alt="Elliam Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link p-dropdown"
                    to="/projects"
                    style={{ position: "relative" }}
                  >
                    PROJECTS
                    <div className="project-dropdown bg-light">
                      <Link to="/projects-listing">PROJECT LISTING</Link>
                      <Link to="" className="p-dropdown-inner">
                        FEATURED PROJECTS &#62;
                        <div className="project-dropdown-inner bg-light">
                          {featuredLinks.map((item) => (
                            <Link key={item.id}
                              to={`/featured-projects/${item.id}`}
                            >
                              {item.title}
                            </Link>
                          ))}
                        </div>
                      </Link>
                    </div>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/capabilities">
                    CAPABILITIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link pr-30" to="/news">
                    NEWS
                  </Link>
                </li>
                <li className="nav-item bg-yellow px-2">
                  <Link className="nav-link px-4" to="/contact">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
      <div className="sticky-top mobile-tablet-nav">
        <nav className="navbar  navbar-expand-lg navbar-light bg-light p-0">
          <div className="container-fluid px-0">
            <Link className="navbar-brand px-4" to="/">
              <img src={logo} alt="Elliam Logo" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarTogglerDemo02"
              aria-controls="navbarTogglerDemo02"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={handleNavShow}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className={`${show ? "" : "showHide"}`}
              style={{ width: "100%" }}
              id="navbarTogglerDemo02"
            >
              <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <Link className="nav-link" to="/about">
                    ABOUT
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/projects">
                    PROJECTS
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/projects-listing">
                    PROJECT LISTING
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link" to="/capabilities">
                    CAPABILITIES
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className="nav-link pr-30" to="/news">
                    NEWS
                  </Link>
                </li>
                <li className="nav-item bg-yellow px-2">
                  <Link className="nav-link px-4" to="/contact">
                    CONTACT US
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Header;
