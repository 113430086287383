import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/news5-01.webp";
import NewsImage2 from "../assets/images/news5-02.webp";
import NewsImage3 from "../assets/images/news5-03.webp";

const News5ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                Sustainability is a core responsibility in the construction industry, and mid tier construction company, Elliam, in Sydney, is at the forefront of making a positive impact. Elliam's commitment to sustainability goes beyond just building structures; it's about reshaping the way we think about construction by focusing on refurbishment and lifecycle extension. Let's delve into how Elliam is contributing to sustainability.​
                  </p>
                  <br />
                  <br />
                  <img
                  src={NewsImage3}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage3"
                />
                <h4 className="fw-600">Refurbishment for Sustainable Revitalization</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                One of Elliam's key contributions to sustainability lies in its approach to building refurbishment. Instead of tearing down and starting from scratch, Elliam believes in the transformative power of refurbishing existing structures. This not only preserves the historical and architectural heritage of Sydney but also significantly reduces construction waste and carbon emissions.
                </p>
                <h4 className="fw-600">Preserving Sydney's Architectural Heritage​</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Sydney is known for its rich architectural heritage, and Elliam is committed to preserving it. Through meticulous refurbishment, Elliam breathes new life into old buildings while retaining their unique character. This not only celebrates the city's history but also contributes to the reduction of solid waste generated by demolition.
                </p>
                <h4 className="fw-600">Minimizing Construction Waste</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Construction waste is a significant environmental concern, but Elliam is addressing it head-on. By opting for refurbishment over new construction, Elliam minimizes waste generation. Salvaging and reusing materials from existing structures not only reduces waste sent to landfills but also conserves resources.                </p>
                <br/>
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage"
                />
                <br />
                <br />
                <h4 className="fw-600">Extending the Lifecycle of Buildings and Equipment</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Lifecycle extension is another pillar of Elliam's sustainability strategy. Instead of replacing building components or equipment prematurely, Elliam focuses on extending their lifespan. By refurbishing and maintaining these assets, Elliam reduces the need for new production, ultimately saving energy and resources.
                </p>
                <h4 className="fw-600">Efficient Resource Utilization</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Elliam understands that sustainable construction is not just about the end result but also about the process. Through efficient resource utilization, Elliam minimizes its environmental footprint during refurbishment projects. Careful planning, resource recycling, and reduced energy consumption are integral parts of every Elliam project.
                </p>
                <br />
                <img
                  src={NewsImage2}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage2"
                />
                <br />
                <br />
                <h4 className="fw-600">Energy Efficiency and Green Technologies</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                As part of its commitment to sustainability, Elliam incorporates energy-efficient technologies in its refurbishment projects. This includes upgrading lighting systems, HVAC systems, and insulation to enhance energy performance. By doing so, Elliam not only reduces operational costs for its clients but also contributes to a greener Sydney.
                </p>
                <h4 className="fw-600">Collaborative Efforts for a Sustainable Future</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Elliam doesn't embark on its sustainability journey alone. Collaboration with local recycling centes, suppliers of sustainable materials, and government initiatives is a cornerstone of Elliam's approach. By partnering with like-minded organizations, Elliam is building a network of sustainability advocates in Sydney's construction sector.
                </p>
                <br />
                <p className="fw-400 fs-17 mb-4">
                In conclusion, Elliam, head quarters in Sydney, is setting an example in sustainable construction practices. Through refurbishment, lifecycle extension, and a commitment to efficient resource utilization, Elliam is not only reducing its environmental impact but also actively contributing to the preservation of Sydney's architectural heritage. As Sydney continues to evolve, Elliam's dedication to sustainability serves as a beacon of responsible construction practices for the entire industry.
                </p>
                <br/>
                <p className="fw-400 fs-17 mb-4">To find out more about Elliam’s refurbishment contact on <a href="mailto:admin@elliam.com.au">admin@elliam.com.au</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.slice(0, 4).map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                 
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News5ContentDetails;
