import React from "react";

import Project1DetailHero from "../components/project1-detail-hero";
import Project1ContentDetails from "../components/project1-content-details";
import Footer from "../components/shared/footer";

const Project1Details = () => {
  return (
    <>
      <Project1DetailHero />
      <Project1ContentDetails />
      <Footer />
    </>
  );
};

export default Project1Details;
