import React from "react";

import data from "../content/news.json";

import Header from "./shared/header";

const News2DetailHero = () => {
  return (
    <div className="main-breadcrum bg-black1">
      <div
        className="container-fluid no-px-tab"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">
                    {data.news[1].date}
                  </span>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    {data.news[1].title}
                  </h1>
                </div>
                {/* <div className="col-lg-6 col-md-12">
                  <p className="text-white fs-18 fw-400">
                    Our 20 years of experience, comprises a substantial
                    portfolio of public and commercial projects which includes;
                    construction of buildings, extensions, refurbishments and
                    fit outs. Elliam has developed a deep level of expertise in
                    the commercial, aged care and state and local government
                    sectors with a total value of projects to date standing at
                    $60 million.
                  </p>
                </div> */}
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default News2DetailHero;
