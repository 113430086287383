import React from "react";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const PeopleCard = ({ image, heading, description, linkLabel, link }) => {
  return (
    <div className="col-md-12 mb-3">
      <div className="mb-3">
        <img src={image} className="img-fluid w-100" alt="Project" />
      </div>
      <div>
        <h4 className="fw-600">{heading}</h4>
        <p className="fs-16 fw-500 mb-2">{description}</p>
        <Link to={link} className="fw-600 fs-15 color-black">
          {linkLabel} <FontAwesomeIcon icon={faArrowRightLong} />
        </Link>
      </div>
    </div>
  );
};

export default PeopleCard;
