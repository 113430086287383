import React from "react";

import ProjectsHero from "../components/projects-hero";
import ProjectsListing from "../components/projects-listing";

const ProjectList = () => {
  return (
    <div>
      <ProjectsHero />
      <ProjectsListing />
    </div>
  );
};

export default ProjectList;
