import React from "react";

import Header from "./shared/header";

const AboutHero = () => {
  return (
    <div
      className="main-breadcrum bg-black1"
      style={{ backgroundColor: "#011C27 !important" }}
    >
      <div
        className="container-fluid no-px-tab"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">About</span>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    Quality at scale
                  </h1>
                </div>
                <div className="col-md-6">
                  <p className="text-white fs-18 fw-400">
                    Elliam is a leader in the construction industry. We are a
                    trusted partner, with a strong track record for quality
                    delivery of new construction builds, fit outs and
                    refurbishments. Elliam’s long standing business partnerships
                    with leaders in education, aged care and local government
                    are founded on our on-time delivery and best practice defect
                    rates
                  </p>
                </div>
                <div className="col-md-6">
                  <p className="text-white fs-18 fw-400">
                    Our culture of excellence and project management has
                    culminated in an impressive range of quality construction
                    projects. Over the last 20 years we have developed a strong
                    network of partnerships including NSW Government project
                    managers, architects, consultants, sub-contractors and
                    suppliers.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default AboutHero;
