import React from "react";
import { Helmet } from "react-helmet-async";

import News5DetailHero from "../components/news5-detail-hero";
import News5ContentDetails from "../components/news5-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/news5-01.webp";

const NewsDetails = () => {
  return (
    <>
     <header>
      <Helmet>
        <meta property="og:title" content={data.news[4].title} />
        <meta property="og:description" content="Discover how Elliam, a leading mid-tier construction company in Sydney, is pioneering sustainability in the construction industry. Through refurbishment and preservation, Elliam celebrates Sydney's architectural heritage, reduces construction waste, and minimizes carbon emissions. Partner with us for a greener and more sustainable approach to construction." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-refurbishment-lifecycle-extension" />
      </Helmet>
      <News5DetailHero />
      </header>
      <News5ContentDetails />
      <Footer />
    </>
  );
};

export default NewsDetails;
