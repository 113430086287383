import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const ElliamAboutHero = () => {
  return (
    <>
      <section className="home-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="fw-600">Elliam difference</h2>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-lg-5">
                <h1 className="fw-700 color-dark-blue fs-50">
                  Not just what we do, but how we{" "}
                  <div className="empty-space"></div> do it
                </h1>
              </div>
              <div className="col-md-6 offset-lg-2 col-lg-5">
                <p className="fs-20 fw-500">
                  We are a trusted partner, with a strong track record for
                  quality delivery of new construction builds, fit outs and
                  refurbishments.
                </p>
                <Link to="/about" className="color-black fw-700">
                  ABOUT ELLIAM &nbsp;{" "}
                  <FontAwesomeIcon icon={faArrowRightLong} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ElliamAboutHero;
