import React from "react";
import { Helmet } from "react-helmet-async";

import News7DetailHero from "../components/news7-detail-hero";
import News7ContentDetails from "../components/news7-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/news7-01.webp";

const NewsDetails = () => {
  return (
    <>
     <header>
      <Helmet>
        <meta property="og:title" content={data.news[6].title} />
        <meta property="og:description" content="Elliam, with 20+ years' experience across 200+ sites in NSW, prioritizes safety compliance, managing hazards like traffic flow and falling objects to ensure a stellar safety record on construction projects." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-safety-hazard-reduction" />
      </Helmet>
      <News7DetailHero />
      </header>
      <News7ContentDetails />
      <Footer />
    </>
  );
};

export default NewsDetails;
