import React from "react";

import Project1 from "../assets/images/p1.jpg";
import Project2 from "../assets/images/p2.jpg";

const AboutProjects = () => {
  return (
    <section className="about-s-2">
      <div className="container-fluid">
        <div className="container">
          <div className="row d-flex align-items-center mb-5">
            <div className="col-md-6">
              <img src={Project1} className="img-fluid w-100" alt="Team" />
            </div>
            <div className="col-md-6 px-5 py-5">
              <h2 className="fw-600">A diverse team</h2>
              <p className="fs-18 fw-400">
                Our team of project managers, enabled by leading construction
                project management technology, has allowed Elliam to keep pace
                with the growing demand for our services.
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center mb-4">
            <div className="col-md-6 px-5">
              <h2 className="fw-600">A trusted partner</h2>
              <p className="fs-18 fw-400">
                Examples of Elliam’s outstanding achievements include an 8-year
                partnership with the New South Wales Department of Housing to
                maintain a 500 million dollar estate, responsibility for the
                refurbishment of all Uniting Aged Care sites within the Western
                Sydney and New South Wales regions and a 6 year contract with
                the Department of Education to refurbish and upkeep school
                premises.
              </p>
            </div>
            <div className="col-md-6">
              <img src={Project2} className="img-fluid w-100" alt="Partners" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutProjects;
