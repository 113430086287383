import React from "react";

import OwlCarousel from "react-owl-carousel";

import ProjectCard from "./shared/project-card";

import Project1 from "../assets/images/project-1.png";
import Project3 from "../assets/images/project-3.png";
import Project4 from "../assets/images/project-4.png";
import Project5 from "../assets/images/project-5.png";
import Project6 from "../assets/images/project-6.png";

const CaseStudies = () => {
  return (
    <section className="home-s-5">
      <div className="container-fluid">
        <div className="container">
          <div className="row mb-3">
            <div className="col-12">
              <h2 className="fs-40 fw-600 color-dark-blue">
                FEATURED PROJECTS
              </h2>
            </div>
          </div>
          <div className="row desktop-screen">
            <OwlCarousel
              className="owl-carousel owl-theme"
              loop
              margin={24}
              dots={false}
              autoplay={true}
              autoplayTimeout={2500}
            >
              <ProjectCard
                image={Project1}
                type="CONSTRUCTION"
                description="Garages for State Emergency Services, Willoughby Council."
                slug="1"
              />
              <ProjectCard
                image={Project3}
                type="MAINTENANCE"
                description="A safer school environment with quality maintenance, Wollongong Department of Schools."
                slug="2"
              />
              <ProjectCard
                image={Project4}
                type="REFURBISHMENT"
                description="Refurbishing 48 Retirement Living Apartments, Uniting Aged Care"
                slug="3"
              />
              <ProjectCard
                image={Project5}
                type="REFURBISHMENT"
                description="Commercial office fit outs, refurbishment of retirement living and residential care, Southern Cross Aged Care"
                slug="4"
              />
              <ProjectCard
                image={Project6}
                type="REFURBISHMENT"
                description="A government partnership since 2015, Sydney to Wollongong Department of Housing."
                slug="5"
              />
            </OwlCarousel>
          </div>
          <div className="tablet-s">
            <OwlCarousel
              className="owl-carousel owl-theme"
              loop
              items={1}
              margin={24}
              dots={false}
              autoplay={true}
              autoplayTimeout={2500}
            >
              <ProjectCard
                image={Project1}
                type="CONSTRUCTION"
                description="Garages for State Emergency Services, Willoughby Council."
                slug="1"
              />
              <ProjectCard
                image={Project3}
                type="MAINTENANCE"
                description="A safer school environment with quality maintenance, Wollongong Department of Schools."
                slug="2"
              />
              <ProjectCard
                image={Project4}
                type="REFURBISHMENT"
                description="Refurbishing 48 Retirement Living Apartments, Uniting Aged Care"
                slug="3"
              />
              <ProjectCard
                image={Project5}
                type="REFURBISHMENT"
                description="Commercial office fit outs and retirement living and residential care, Southern Cross Aged Care."
                slug="4"
              />
              <ProjectCard
                image={Project6}
                type="REFURBISHMENT"
                description="A government partnership since 2015, Sydney to Wollongong Department of Housing."
                slug="5"
              />
            </OwlCarousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CaseStudies;
