import React from "react";

import Footer from "../components/shared/footer";
import Project6DetailHero from "../components/project6-detail-hero";
import Project6ContentDetails from "../components/project6-content-details";

const Project1Details = () => {
  return (
    <>
      <Project6DetailHero />
      <Project6ContentDetails />
      <Footer />
    </>
  );
};

export default Project1Details;
