import React from "react";

// import Hero from "../components/Hero";
import Hero from "../components/Hero";
import ElliamAboutHero from "../components/elliam-about-hero";
import HowWeWorks from "../components/how-we-works";
import WhatWeDo from "../components/what-we-do";
import CaseStudies from "../components/case-studies";
import People from "../components/People";
import Footer from "../components/shared/footer";

const Home = () => {
  return (
    <>
      <Hero />
      <ElliamAboutHero />
      <HowWeWorks />
      <WhatWeDo />

      <CaseStudies />
      <People />
      <Footer />
    </>
  );
};

export default Home;
