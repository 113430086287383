import gorvment01 from "../assets/images/government01.png";
import gorvment02 from "../assets/images/government02.png";
import gorvment03 from "../assets/images/government03.png";
import gorvment04 from "../assets/images/government04.png";
import gorvment05 from "../assets/images/government05.png";
import gorvment06 from "../assets/images/government06.png";
import gorvment07 from "../assets/images/goverment07.webp";
import gorvment08 from "../assets/images/goverment08.webp";
import agedcare01 from "../assets/images/agedcare1.png";
import agedcare02 from "../assets/images/agedcare2.png";
import agedcare03 from "../assets/images/agedcare3.png";
import agedcare04 from "../assets/images/agedcare4.png";
import agedcare05 from "../assets/images/agedcare5.png";
import agedcare06 from "../assets/images/agedcare6.png";
import agedcare07 from "../assets/images/agedcare7.png";
import agedcare08 from "../assets/images/agedcare8.png";
import agedcare09 from "../assets/images/agedcare9.png";
import agedcare10 from "../assets/images/agedcare10.webp";
import agedcare11 from "../assets/images/agedcare11.webp";

import residential01 from "../assets/images/residential01.png";
import residential02 from "../assets/images/residential02.png";
import residential03 from "../assets/images/residential03.png";

import council01 from "../assets/images/pro1-1.png";
import council02 from "../assets/images/pro2-1.webp";
import council03 from "../assets/images/pro3-1.webp";
import council04 from "../assets/images/pro4-1.webp";

import commerical01 from "../assets/images/commerical01.jpeg";
import commerical02 from "../assets/images/commerical02.jpeg";
import commerical03 from "../assets/images/commerical03.webp";
import commerical04 from "../assets/images/commerical04.webp";

export const data = {
  government: [
    {
      id: "1",
      title: "Department of Housing, 39 Lawford st",
      type: "Refurbishment",
      location: "Greenacre",
      description:
        "The replacement of flooring to 4 town houses as part of a popular Department of Housing complex in Greenacre. Elliam's team implemented an engineered, hybrid flooring and indoor and outdoor tiles. Elliam's team work with the highest quality material and are skilled in all flooring finishes.",
      imagepath: gorvment01,
      scope: "Underpinning and replacement of flooring to townhouses",
      time: "5 months",
      value: 462000,
    },
    {
      id: "2",
      title: "Department of Housing, Chiswick st",
      type: "Refurbishment",
      location: "Greenacre",
      description:
        "Elliam repaired cracked and damaged drainage to protect the building’s structural integrity. Elliam's work focused on the sub floor drainage and gravity systems to protect the building’s foundations and prevent brickwork drawing water into walls. Elliam provide a range of foundation repairs with licensed underpinners. Our solutions address rising damp, storm water, sewer or foundation repairs.",
      imagepath: gorvment02,
      scope: "Drainage refurb",
      time: "4 weeks",
      value: 200000,
    },
    {
      id: "3",
      title: "Department of Education, Painting roll out",
      type: "Refurbishment",
      location: "Wollongong",
      description:
        "Ongoing painting maintenance to Wollongong Schools. Elliam were able to work in windows of time outside of school hours and during vocation time in order to reduce the impact on teachers and students. Elliam have commercial painting services that include general painiting,  repainting and ongoing painting maintenance as required.",
      imagepath: gorvment03,
      scope: "Painting",
      time: "4 weeks",
      value: 250000,
    },
    {
      id: "4",
      title: "Department of Housing, Irvine st",
      type: "Refurbishment",
      location: "Gwyneville",
      description:
        "Elliam work with the Department of Housing to implement cost effective refurbs which both improve appearance and reduce ongoing maintenance. Elliam applied vinyl cladding to the house to help preserve the wood of existing buildings and reduce ongoing maintenance costs. Elliam works with house cladding to achieve a number of positive outcomes including reduced maintenance, energy efficiency, weather protection and improved appearance.",
      imagepath: gorvment04,
      scope: "Cladding/windows",
      time: "4 weeks",
      value: 42000,
    },
    {
      id: "5",
      title: "Department of Housing",
      type: "Refurbishment",
      location: "Sydney to Wollongong",
      description:
        "New South Wales has an estimated 96,000 dwellings, with 12,000 in the Wollongong region and 40,000 in Sydney. Elliam has run a continuous maintenance program for the Department of Housing which has spanned over 7 years. Elliam's project managers are skilled in working within communities in social housing settings, including tenants with special health considerations. Elliam's social housing work includes high levels of accountability for a safe and timely delivery of work.",
      imagepath: gorvment05,
      scope: "Fencing and general maintenance",
      time: "7 years",
      value: 7000000,
      slug: 5,
    },
    {
      id: "6",
      title: "School Maintenance",
      type: "Maintenance",
      location: "Wollongong",
      description:
        "Wollongong has over 50 schools, serving a population of 290,000. Elliam project managers scheduled maintainance activities with minimum disruption to school terms. Elliam has a dedicated school maintenance team for the Wollongong District who prioritise work within designated windows outside of school hours.",
      imagepath: gorvment06,
      scope: "Refencing, painting and installation of security cameras",
      time: "5 years",
      value: 5000000,
      slug: 2,
    },
    {
      id: "7",
      title: "Department of Defence",
      type: "Full house refurbishment​",
      location: "Greater Sydney",
      description:
        "In addition to waterproofing the basement, we undertook a comprehensive transformation of the outdoor area. By removing existing tiles, installing new stormwater solutions, and introducing contemporary tiling, we revitalised the outdoor space. The result is an aesthetically pleasing, functional, and weather-resistant area that adds value to the entire property.​",
      imagepath: gorvment07,
      scope: "Overcome: Basement water ingress and outdoor area revitalisation​",
      time: "9 weeks",
      value: 1000000,
    },
    {
      id: "8",
      title: "Department of Defence, Waterproofing",
      type: "Waterproofing and Refurbishment",
      location: "Greater Sydney",
      description:
        "Elliam provided waterproofing and drainage to Department of Defence housing as part of a refurbishment project to ensure long-term durability of the property. This initiative aligns with government priorities for sustainable housing and infrastructure resilience. Elliam works closely with the Department of Defence’s objectives  to deliver refurbishment projects to house resilient and liveable communities.",
      imagepath: gorvment08,
      scope: "4 properties",
      time: "1 year",
      value: 600000,
    },
  ],
  agedcare: [
    {
      id: "1",
      title: "Southern Cross Care Commercial kitchen",
      type: "Refurbishment",
      location: "Goulburn",
      description:
        "Nestled in a rural setting, Tension Residential Care is a vibrant part of Goulburn's community. Elliam replaced a commercial kitchen which served a community of 100 residents and their guests. Elliam's project managers designed the construction process to involve minimum disruption to daily living. Construction workers and equipment were confined to partioned areas, strict working hours and discreet entries and exits.",
      imagepath: agedcare01,
      scope: "Commercial kitchen replacement",
      time: "7 weeks",
      value: 280000,
    },
    {
      id: "2",
      title: "Southern Cross Care Cootamundra",
      type: "Refurbishment",
      location: "Cootamundra",
      description:
        "Cootamundra's Southern Cross Care incorporates 55 units set within extensive gardens within easy reach of the local community. Elliam worked to restore fire affected areas including fire, smoke and soot damage to shared living areas and residential units. Elliam's project management ensures a safe working environment through the removal of non recoverable material.  Smoke odor was removed through the use of a hydroxyl generator.",
      imagepath: agedcare02,
      scope: "Fire remediation",
      time: "6 weeks",
      value: 408000,
    },
    {
      id: "3",
      title: "Salvation Army Care Bass Hill",
      type: "Refurbishment",
      location: "Bass Hill",
      description:
        "Weroona at Bass Hill is a 15 acre property with retirement living and residential aged care. Elliam refurbished two rooms within the residential care complex. Elliam successfully worked to reduce the impact on resident's daily life by limiting work hours and expediting the completion of the project.",
      imagepath: agedcare03,
      scope: "Refurb to two rooms",
      time: "3 weeks",
      value: 58000,
    },
    {
      id: "4",
      title: "Salvation Army Care Nurrabundah",
      type: "Refurbishment",
      location: "Nurrabundah ACT",
      description:
        "Just a short drive from Canberra, Nurrabundah is a 67 room residential care facility, servicing a range of specialised healthcare needs. Elliam refurbished eight rooms within the respite residential care complex. Elliam's project managers maintained communication with the facility staff and residential community about the refurbishment plans and progress, including use of signage and site maps to redirect foot traffic away from construction areas.",
      imagepath: agedcare04,
      scope: "Refurb to 8 rooms",
      time: "9 weeks",
      value: 176000,
    },
    {
      id: "5",
      title: "Salvation Army Care Collaroy",
      type: "Refurbishment",
      location: "Collaroy",
      description:
        "Located in the sunny beachside suburb of Collaroy, Elizabeth Jenkins Place is a sequence of 7 interconnected houses overlooking the ocean and garden. Elliam refurbished a 2 bedroom unit which included a open plan living space, a dining space, replacing the kitchen and upgrading the bathroom. Elliam's team are highly experienced in working in aged care communities, addressing resident's privacy and interacting respectfully where required.",
      imagepath: agedcare05,
      scope: "Refurb to 2 bed unit",
      time: "4 weeks",
      value: 82000,
    },
    {
      id: "6",
      title: "Uniting Care retirement and residential living",
      type: "Refurbishment",
      location: "Western Sydney and Western NSW",
      description:
        "Uniting is a provider of premium retirement living and residential communities, with 1600 sites Australia wide. Elliam was able to deliver all 48 refurbishments within the 3 to 4 week turn around time, allowing new residents to take possession of their home without delays. Eliam's project managers work with a cloud based project management system which is connected to every employee and contractor device. Our project management system gives us a real time and remote view into the progress of a specific project.",
      imagepath: agedcare06,
      scope: "Installing new kitchens, bathrooms and paint",
      time: "3 years",
      value: 15000000,
      slug: 3,
    },
    {
      id: "7",
      title: "Southern Cross Care Sutherland",
      type: "Refurbishment",
      location: "Sutherland",
      description:
        "Situated within a suburban street conveniently located near the local shopping precinct, Southern Cross Nagle Home is part of bustling Sutherland. Elliam refurbished 2 ensuites giving them a modern and refreshing uplift. Elliam have a dedicated refurbishment team who specialising in high quality, fast turn around work.",
      imagepath: agedcare07,
      scope: "Refurb to bathroom",
      time: "3 weeks",
      value: 16000,
    },
    {
      id: "8",
      title: "Southern Cross, Aged Care",
      type: "Refurbishment",
      location: "New South Wales",
      description:
        "Southern Cross Care NSW ACT has over 2,000 employees in 40 locations, serving the community for over 50 years. Elliam has worked on a diverse range of sites from Eastern and Western Sydney to the central coast. Elliam's project team were able to safeguard workers and the community during the Covid outbreak using masks, face shields, coding, testing and the use of onsite lunches to reduce movement.",
      imagepath: agedcare08,
      scope: "Refurbishment of apartments and fit out of commerical space",
      time: "2 years",
      value: 5000000,
      slug: 4,
    },
    {
      id: "9",
      title: "RSL Care",
      type: "Refurbishment",
      location: "Narrabeen",
      description:
        "Narrabeen RSL Retirement Village includes 710 dwellings. Elliam renovated 2 units to give them an uplift in finish and features. Elliam's has 20 years experience in retirement living renovation, and is regarded as a leader in the field.",
      imagepath: agedcare09,
      scope: "Renovation to two units",
      time: "4 weeks",
      value: 140000,
    },
    {
      id: "10",
      title: "Catholic Healthcare",
      type: "Refurbishment",
      location: "Greater Sydney",
      description:
        "Elliam refurbished 8 units over a 24 month period in Kogarah, Maroubra and Brookvale. During Catholic Healthcare’s refurbishment, Elliam was able to safeguard the community well-being and safety. The construction team minimised disruptions by scheduling activities thoughtfully, addressing noise concerns, and organising phased construction. Project managers established communications channels to promptly address resident concerns. The on-time delivery and minimal defect rates ensured incoming residents could move in on schedule.",
      imagepath: agedcare10,
      scope: "12 units",
      time: "2 years",
      value: 600000,
    },
    {
      id: "11",
      title: "Southern Cross Care, Driveway concreting​",
      type: "Construction",
      location: "Deniliquin and Central NSW",
      description:
        "The construction services involved maintaining emergency and visitor access and safety considerations. Elliam excavated the area, prepared and poured concrete and finished the area to achieve the desired grade for drainage including a non-slip finish to meet safety considerations. Elliam ensured the finished driveway complied with accessibility standards, providing ramps or other features if necessary for residents with mobility challenges.",
      imagepath: agedcare11,
      scope: "Driveway construction",
      time: "4 years",
      value: 5000000,
    },
  ],
  residential: [
    {
      id: "1",
      title: "Private, Hereford st",
      type: "Construction",
      location: "Glebe",
      description:
        "A Victorian heritage and modern blend home which benefited from improvements in air flow and light. Elliam constructed a new kitchen, bathroom, living spaces and a granny flat. Elliam's specialised hertiage team are able to work with traditional and modern building practices to breathe new life into historic buildings.",
      imagepath: residential01,
      scope: "Renovation to existing house and granny flat",
      time: "5 Months",
      value: 380000,
    },
    {
      id: "2",
      title: "Private, Duplex build",
      type: "Construction",
      location: "Peakhurst",
      description:
        "A modern architect designed duplex of two, 2 bedroom town houses with 3 distinct residences. Elliam's residential constructions are delivered with a minimal defection rate, much less than average constructions. Elliam's residential constructions have a strong track record of on time and on budget delivery.",
      imagepath: residential02,
      scope: "Duplex with granny flats",
      time: "10 months",
      value: 1250000,
    },
    {
      id: "3",
      title: "Strata, Market st",
      type: "Refurbishment",
      location: "Rockdale",
      description:
        "Strata invested in waterproofing to maintain building integrity and reduce ongoing maintenance costs. Elliam applied  waterproofing membranes to foundations, roof, balconies and retaining walls. Elliam work with high quality durable membranes which are ideally suited to the extremes in Australian climate.",
      imagepath: residential03,
      scope: "Waterproofing",
      time: "6 weeks",
      value: 178000,
    },
  ],
  council: [
    {
      id: "1",
      title: "Naremburn Security Emergency Services- Garages",
      type: "Construction",
      location: "Naremburn",
      description:
        "State Emergency Services is a volunteer organisation serving the Willoughby and Lane Cove local area for flood and storm emergencies. Elliam completed the project ahead of schedule, which was constructed with special safety precautions to protect the local community. Ellaim's cloud based project management system allowed our client to access digital image records of site progress, 24/7, through their own devices.",
      imagepath: council01,
      scope: "Construction of new garages for Willoughby Council",
      time: "2 years",
      value: 2000000,
      slug: 1,
    },
    {
      id: "2",
      title: "Cumberland Council",
      type: "Refurbishment",
      location: "Merryland’s library",
      description:
        "Merryland’s library is the bustling centre for many community groups and has an extensive range of books and online resources.  Elliam worked with Cumberland Council  on the recladding of the library’s exterior using James Hardie’s Exo tec cladding and the replacement of windows.  The project required the library to remain operational during the process.  Situated next to the Council’s admin and strategically located in the Merryland’s central business district Elliam were charged with both minimising disruption to council workers and the surrounding community. ​",
      imagepath: council02,
      scope: "Cladding",
      time: "5 month",
      value: 470000,
      slug: 6,
    },
    {
      id: "3",
      title: "Cumberland Council​",
      type: "Refurbishment",
      location: "Chester Hill Sports Park, remediation​",
      description:
        "Chester Hill Sports Park boasts a series of grass sports fields hosting football games and their fans, joggers and dog walkers. The park is an important recreational centre for the local community. As a family park, the safety and cleanliness of the toilets and amenities is critical for its ongoing use.<br/> <br/>Elliam were contracted to remove contaminated and toxic substances and correct water damaged related building defects in the ground's amenities.",
      imagepath: council03,
      scope: "Toilets and amenities",
      time: "2 month",
      value: 200000,
    },
    {
      id: "4",
      title: "Willoughy Council",
      type: "Refurbishment",
      location: "Incinerator Art Space, remediation and historic refurbishment​",
      description:
        "A heritage listed Walter Burley-Griffin designed incinerator converted into public art space and café. Willoughby Council engaged Elliam to remediate contaminated areas around the space and  restore and refurbish the building.<br/><br/>Work included close collaboration with a heritage architect who advised on matching the original paint from 60 years ago. Elliam reused original sandstone from the site and restored previously fenced off contaminated areas to a grass covered public area.",
      imagepath: council04,
      scope: "Toilets and amenities",
      time: "4 month",
      value: 370000,
    },
  ],
  commerical: [
    {
      id: "1",
      title: "4 in Hand Hotel",
      type: "Fit out",
      location: "Paddington",
      description:
        "The ‘4 in Hand’ is a landmark of historic Paddington. Next to the buzz of its popular bar, is the intimacy of its inviting, fine dining bistro. Elliam worked with ‘4 in Hand’ to renovate the dining space to meet the discerning standards of its hatted chef and his devoted customers. Elliam’s fit out combined our heritage and fit out teams, to successfully blend the pub’s Victorian charm with fresh, state of the art features.",
      imagepath: commerical01,
      scope: "Remove TAB and install dining room",
      time: "8 weeks",
      // value: 2000000,
    },
    {
      id: "2",
      title: "Commodore Hotel",
      type: "Fit out",
      location: "McMahons Point",
      description:
        "The iconic Commodore Hotel in MacMahons Point, is situated in the heart of the residential village on Blue’s Point Road. Elliam renovated the indoor bar and gaming lounge, as part of an investment to reinvigorate the popular venue. Elliam’s pub fit outs are backed by 20 years experience and includes some of Sydney’s most famous watering holes.",
      imagepath: commerical02,
      scope: "Gaming room extension and renovation to the existing bar.",
      time: "7 weeks",
      // value: 2000000,
    },
    {
      id: "3",
      title: "Bar Brighton",
      type: "Awning",
      location: "Bar Brighton Le Sands",
      description:
        "Bar Brighton is a local landmark in Brighton Le Sands. This thriving venue over looking the beach, chose Elliam to replace the hotel’s awning. The replacement and upgrade of the awning roof was a complex job involving council approval and traffic management. In order to deliver the awning to site, Elliam project managed a road closure of the main highway, known locally as Grand Parade.",
      imagepath: commerical03,
      scope: "Pool, pool area and function room refurbishment",
      time: "80 days",
      value: 320000,
    },
    {
      id: "4",
      title: "Manly Pacific Hotel",
      type: "Fit Out",
      location: "Manly",
      description:
        "The Manly Pacific Hotel appointed Elliam to upgrade its guests facilities to breath new life into this beach side iconic gem. Elliam demolished and renovated the rooftop pool and pool area to invest in its 5 star appeal for the years ahead. In addition, Elliam refurbed the downstairs bar and Premium Room in anticipation of the return of domestic and international travellers after lockdown.",
      imagepath: commerical04,
      scope: "Pool, pool area and function room refurbishment",
      time: "3 month",
      value: 1300000,
    },
  ],
};
