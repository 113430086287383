import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import ReactTextTransition, { presets } from "react-text-transition";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const HowWeWorks = () => {
  const texts = ["Safety", "Respect", "Sustainability", "Integrity"];
  const [textIndex, setTextIndex] = useState(0);

  useEffect(() => {
    setInterval(() => {
      setTextIndex(textIndex + 1);
    }, 2000);
  }, [textIndex]);

  return (
    <>
      <section className="home-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-12 py-5">
                <h1 className="text-white h-s3-h1 fw-700">
                  We work with{" "}
                  <span className="txt-rotate color-light-blue">
                    <ReactTextTransition
                      text={texts[textIndex % texts.length]}
                      springConfig={presets.gentle}
                      style={{ margin: "0 4px" }}
                      inline
                    />
                  </span>
                </h1>
              </div>
              <div className="col-md-12 pb-5">
                <Link
                  to="/capabilities"
                  className="text-white fs-24 fw-600"
                  style={{ float: "right", textDecoration: "none" }}
                >
                  View capabilities <FontAwesomeIcon icon={faArrowRightLong} />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HowWeWorks;
