import React from "react";

import Header from "./shared/header";

const CapabilitiesHero = () => {
  return (
    <div className="main-breadcrum bg-black1">
      <div
        className="container-fluid no-px-tab"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">Capabilities</span>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    20 years of excellence
                  </h1>
                </div>
                <div className="col-lg-6 col-md-12">
                  <p className="text-white fs-18 fw-400">
                    Elliam's people first approached is back by our values and
                    extensive experience working within and alongside local
                    communities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default CapabilitiesHero;
