import React from "react";
import { Helmet } from "react-helmet-async";

import News2DetailHero from "../components/news2-detail-hero";
import News2ContentDetails from "../components/news2-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/news2.jpg";

const News2Details = () => {
  return (
    <>
    <header>
       <Helmet>
        <meta property="og:title" content={data.news[1].title} />
        <meta property="og:description" content="Discover Elliam's exceptional heritage refurbishment expertise, spanning over a century. We seamlessly combine modern amenities with timeless craftsmanship to revitalize historic buildings. Explore our portfolio, including iconic projects like Wollongong Public School and Sydney's Department of Housing Estates." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-02" />
      </Helmet>
      <News2DetailHero />
    </header>
      <News2ContentDetails />
      <Footer />
    </>
  );
};

export default News2Details;
