import React from "react";

import Project3DetailHero from "../components/project3-detail-hero";
import Project3ContentDetails from "../components/project3-content-details";
import Footer from "../components/shared/footer";

const Project1Details = () => {
  return (
    <>
      <Project3DetailHero />
      <Project3ContentDetails />
      <Footer />
    </>
  );
};

export default Project1Details;
