import React from "react";

import Project4DetailHero from "../components/project4-detail-hero";
import Project4ContentDetails from "../components/project4-content-details";
import Footer from "../components/shared/footer";

const Project1Details = () => {
  return (
    <>
      <Project4DetailHero />
      <Project4ContentDetails />
      <Footer />
    </>
  );
};

export default Project1Details;
