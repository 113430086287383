import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/news4-01.jpeg";
import NewsImage2 from "../assets/images/news4-02.jpeg";

const News4ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                  Regulation and growing environmental awareness, are driving
                  green building trends that will define our built environment
                  in years to come. To help our clients navigate their way to a
                  greener future, Elliam have identified those trends most
                  likely to shape their construction decisions in the years
                  ahead. In our greener future, we can expect a closer
                  partnership with nature, more energy efficient designs, less
                  waste, more recycled materials, and a reduction of our
                  operational energy usage.
                  <br />
                  <br />
                  <h2 className="fw-600">Green building practices</h2>
                  <br />
                  Best practice green building starts at the construction site.
                  With the right project management, green building practices
                  not only improve environmental outcomes, but they also save
                  money, by reducing waste. Green building practices on
                  construction sites, create a healthier place to work for
                  construction workers and a healthier place for future
                  inhabitants.
                </p>
                <p className="fw-400 fs-17 mb-4">
                  Green building practices are designed to reduce waste, reduce
                  the dispersal of chemicals into the local community and reduce
                  chemical residue. There are a vast number of green building
                  practices in play, some of the more common practices include;
                </p>
                <ul>
                  <li>
                    Minimising stormwater pollution, by the application of silt
                    fencing to prevent bad water flowing into the local sewerage
                    system.
                  </li>
                  <li>
                    Preventing chemicals transference into the local community,
                    using ‘walk off mats’ which remove dirt and dangerous
                    chemicals from a worker’s shoes.
                  </li>
                  <li>
                    Spill containment, a system to stop the spread of hazardous
                    chemicals from the spill source, A reduction in cleaning
                    chemicals, through use of physical processes to clean and
                    the use of detergents to replace harsh chemicals.
                  </li>
                  <li>
                    Airborne chemical reduction, using paste and pellets to
                    replace powders, paint brushes instead of aerosols and
                    ventilated booths for spray painting.
                  </li>
                  <li>
                    Reductions in material waste, using bolts and clips rather
                    than adhesives and the purchase of ready cut material rather
                    than cutting on site.
                  </li>
                </ul>
                <p className="fw-400 fs-17 mb-4">
                  Expect to see innovation in healthier and greener building
                  practices to continually raise the bar on environmentally
                  friendly construction.
                </p>
                <br />
                <h4 className="fw-600">Healthier indoor spaces</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  The pandemic has made permanent changes to our lifestyle, work
                  life and accordingly to our built environment. Alongside the
                  pandemic there has been a growth in demand for improved air
                  purification and natural ventilation, to reduce the load of
                  airborne germs and viruses. Expect to see a growing level of
                  investment in natural indoor air ventilation to both reduce
                  indoor air pollution and the risk of infectious disease.
                </p>
                <p className="fw-400 fs-17 mb-4">
                  In addition, people are increasingly aware of the impact of
                  their built environments on their overall well-being. People
                  are now seeking environments that improve their sensory
                  experience including; sound cancelling design, greater access
                  to natural light and a constant flow of fresh air. In the
                  future you can expect to experience indoor living spaces that
                  feel more connected to the natural world outside.
                </p>
                <br />
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage"
                />
                <br />
                <br />
                <h4 className="fw-600">Biophilia, design inspired by nature</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Popularised by architects such as Frank Lloyd Wright,
                  biophilia aims to mimic nature in designs. Biomimicry is used
                  by engineers to design sustainable materials and by architects
                  in designs such as windows which model flowers, to catch more
                  sunlight. Biophilia is set to be a driving force behind green
                  building innovation in years to come.
                </p>
                <h4 className="fw-600">Lower embodied carbon materials</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  The quest for lower embodied carbon materials will be ongoing,
                  driving research and development into lower carbon options for
                  cement, steel and glass. The rise in use of reusable and
                  natural materials, including the growth in wood-based
                  constructions will continue. The gains will be environmental
                  and financial. Expect to see increasing incentives to swap
                  over traditional materials for more sustainable options, such
                  as bamboo and recycled plastics for flooring.
                </p>
                <h4 className="fw-600">Low-Emitting Windows</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Buildings contain many carbon-saturated activities such as
                  lighting, heating, cooling and ventilation, which together
                  account for 80% of a building’s carbon emissions, known as
                  operational carbon emissions. Low-emitting windows are one the
                  most effective interventions to reduce operational carbon.
                  Low-emitting windows, provide all the pleasure of sunlight
                  rooms without the downside of temperature extremes of
                  overheating or freezing. Coated with metallic oxide to block
                  some of the sun’s excessive rays in summer and retain the heat
                  in winter, low emitting windows reduce energy bills all year
                  round.
                </p>
                <br />
                <img
                  src={NewsImage2}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage2"
                />
                <br />
                <br />
                <h4 className="fw-600">Cool roofs</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Cool roofs are one of the most powerful interventions to
                  reduce the cost of cooling a building. Working on the same
                  principle as white cars or clothes, cool roofs absorb less
                  solar energy due to the solar reflectance of their roof
                  design. Unlike a traditional roof which traps the sun’s heat
                  and radiates it within the living space of the building, cool
                  roofs don’t generate internal heat within the home. Cooler
                  roofs are the same price as regular roofs and are ideally
                  implemented at the time of construction or roof replacement. A
                  cool roof surface can be retrofitted later at an additional
                  cost. Cool roofs are often adopted by buildings seeking a net
                  zero carbon emission target.
                </p>
                <h4 className="fw-600">Climate change preparedness</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Property Managers and homeowners are increasingly preparing
                  themselves for a world of extreme weather. The climate
                  proofing solutions of buildings are designed to manage the
                  specific risks of a geographic area such as flooding, extreme
                  temperature variations and the need for emergency measures
                  such as backup generators.
                  <br />
                  Expect to see a growing demand for flood fortification of
                  buildings, particularly in the flood affected areas of the
                  east coast of Australia. Building designs that use natural and
                  outdoor elements to counter weather extremes, such as sunlight
                  and ventilation, will be on the rise. Cool roofs, better
                  design of skylights and windows, will be used to both improve
                  the ambience and energy efficiency of the buildings of the
                  future.
                </p>
                <h4 className="fw-600">Water conservation</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Water conservation is a growing in awareness and practice,
                  especially in drought effected areas that are impacted by
                  water restrictions. Water conservation solutions include
                  sensor driven water efficient taps, energy efficient toilets
                  and rainwater collection. Recycled gray water for toilets are
                  another way buildings can reduce their overall water
                  consumption.
                </p>
                <h4 className="fw-600">Prefabricated buildings</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Breakthroughs in the preparation of prefabricated buildings
                  have produced unprecedented savings in the cost of building
                  materials and the cost of a build. The efficiency of building
                  in a controlled environment uses much less energy, in
                  addition, the modular design of prefabrication creates
                  efficiencies in resource planning, resulting in a significant
                  reduction of building material waste. Expect to see the
                  efficiencies of prefabricated buildings rise in popularity.
                </p>
                <h4 className="fw-600">Smart buildings</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Smart buildings use sensors and controllers to monitor and
                  regulate energy use to great effect. Sensor and controllers,
                  orchestrate the settings for lighting, ventilation, and
                  temperature control, reducing energy waste. Many green energy
                  futurists see smart buildings as a key driver of future energy
                  and cost savings in the years ahead.
                </p>
                <p className="fw-400 fs-17 mb-4">
                  For more information on how Elliam’s green building solutions
                  can be integrated into your construction needs contact us on{" "}
                  <a href="mailto: admin@elliam.com.au ">
                    {" "}
                    admin@elliam.com.au{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.slice(0, 3).map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News4ContentDetails;
