import React, { useState } from "react";

import { data } from "../content/projects.js";

import ProjectListingCard from "./shared/project-listing-card";

const ProjectsListing = () => {
  const [showAll, setShowAll] = useState(true);
  const [showGovt, setShowGovt] = useState(false);
  const [showRes, setShowRes] = useState(false);
  const [showAge, setShowAge] = useState(false);
  const [showCou, setShowCou] = useState(false);
  const [showCom, setShowCom] = useState(false);

  const handleAll = () => {
    setShowAll(true);
    setShowGovt(false);
    setShowRes(false);
    setShowAge(false);
    setShowCou(false);
    setShowCom(false);
  };

  const handleGovt = () => {
    setShowAll(false);
    setShowGovt(true);
    setShowRes(false);
    setShowAge(false);
    setShowCou(false);
    setShowCom(false);
  };

  const handleRes = () => {
    setShowAll(false);
    setShowGovt(false);
    setShowRes(true);
    setShowAge(false);
    setShowCou(false);
    setShowCom(false);
  };

  const handleAge = () => {
    setShowAll(false);
    setShowGovt(false);
    setShowRes(false);
    setShowAge(true);
    setShowCou(false);
    setShowCom(false);
  };

  const handleCou = () => {
    setShowAll(false);
    setShowGovt(false);
    setShowRes(false);
    setShowAge(false);
    setShowCou(true);
    setShowCom(false);
  };
  const handleCom = () => {
    setShowAll(false);
    setShowGovt(false);
    setShowRes(false);
    setShowAge(false);
    setShowCou(false);
    setShowCom(true);
  };
  return (
    <section className="projects-s-3">
      <div className="container-fluid px-0">
        <div className="bg-black">
          <div className="container">
            <div className="row">
              <div className="col-md-2">
                <p
                  className="text-white fs-18 fw-600"
                  style={{
                    position: "relative",
                    top: "14px",
                    textDecoration: "none",
                  }}
                >
                  Filters
                </p>
              </div>
              <div className="col-md-10">
                <ul
                  className="nav nav-tabs project-tab-nav p-2"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item">
                    <button
                      className={`nav-link ${showAll ? " active" : ""}`}
                      type="button"
                      onClick={handleAll}
                    >
                      Show All
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${showGovt ? " active" : ""}`}
                      type="button"
                      onClick={handleGovt}
                    >
                      GOVERNMENT
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link ${showRes ? " active" : ""}`}
                      type="button"
                      onClick={handleRes}
                    >
                      RESIDENTIAL
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link btnNew ${showAge ? " active" : ""}`}
                      type="button"
                      onClick={handleAge}
                    >
                      AGED CARE
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link btnNew ${showCou ? " active" : ""}`}
                      type="button"
                      onClick={handleCou}
                    >
                      COUNCIL
                    </button>
                  </li>
                  <li className="nav-item">
                    <button
                      className={`nav-link btnNew ${showCom ? " active" : ""}`}
                      type="button"
                      onClick={handleCom}
                    >
                      COMMERICAL
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row desktop-screen">
            {showAll || showGovt ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Government
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.government.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                          slug={project?.slug}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showRes ? (
              <section className="home-s-5">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Residential
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.residential.map((project) => {
                      return (
          
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                          slug={project?.slug}
                        />
                  
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showAge ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Aged care
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.agedcare.map((project) => {
                      return (
                   
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                          slug={project?.slug}
                        />
                     
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showCou ? (
              <section className="home-s-5">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">Council</h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.council.map((project) => {
                      return (
                      
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                          slug={project?.slug}
                        />
                       
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showCom ? (
              <section className="home-s-5">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Commercial
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.commerical.map((project) => {
                      return (
                    
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                          slug={project?.slug}
                        />
                    
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
          </div>
          <div className="tablet-s">
            {showAll || showGovt ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Government
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.government.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                        />
                      
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showRes ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Residential
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.residential.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showAge ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Aged Care
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.agedcare.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showCou ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">Council</h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.council.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
            {showAll || showCom ? (
              <section className="home-s-5 bg-light">
                <div className="container">
                  <div className="row mb-3">
                    <div className="col-12">
                      <h2 className="fs-40 fw-600 color-dark-blue">
                        Commercial
                      </h2>
                    </div>
                  </div>
                  <div className="row">
                    {data.commerical.map((project) => {
                      return (
                        <ProjectListingCard
                          client={project.title}
                          location={project.location}
                          service={project.type}
                          scope={project.scope}
                          time={project.time}
                          value={project.value}
                          description={project.description}
                          image={project.imagepath}
                        />
                      );
                    })}
                  </div>
                </div>
              </section>
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProjectsListing;
