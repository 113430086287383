import React from "react";

const CookiesPopup = ({ handleCookies }) => {
  return (
    <div className="cookie-disclaimer">
      <div className="cookie-close accept-cookie">
        <i className="fa fa-times"></i>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-8">
            <p>
              We use cookies our Elliam’s website to track your experience,
              measure engagement, provide third party content and improve our
              marketing effectiveness.{" "}
            </p>
          </div>
          <div className="col-md-4" style={{ textAlign: "right !important" }}>
            <button
              type="button"
              className="accept-cookies-btn accept-cookie"
              onClick={handleCookies}
            >
              Accept Cookies
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CookiesPopup;
