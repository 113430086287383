import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/pro1-1.png";
import ProjectImage2 from "../assets/images/pro1-2.jpg";

const Project1ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Value</b> &nbsp; $2,000,000
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Completion Date</b> &nbsp;
                  November 2021
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Client Reference</b> &nbsp; Willoughby
                  Council
                </p>
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Elliam completed the project under budget and 2 days before
                    scheduled completion date with one minor defect, rectified
                    in 24 hours.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Project Management</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Our Project Management system provided paper free, fully
                    digital records accessible through devices, 24/7 to our team
                    and client stakeholders.
                  </li>
                  <li className="fs-17">
                    Our platform login system is location and time stamped,
                    allowing project managers to track time spent on site by
                    individual workers.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Elliam had zero safety incidences on the project.
                  </li>
                  <li className="fs-17">
                    Elliam successfully managed a Covid Safe workspace in full
                    compliance to regulations.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Systems</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    The platform has image records of task completion, allowing
                    tracking of task compliance and on time delivery.
                  </li>
                  <li className="fs-17">
                    Elliam managed a vaccination compliant team, with ongoing,
                    onsite vaccination tests, QR code logins and daily team
                    temperature and Covid compliance questions.
                  </li>
                  <li className="fs-17">
                    All Covid testing records were uploaded to the project
                    management system real time and held centrally at Elliam.
                  </li>
                  <li className="fs-17">
                    Elliam’s digital project management system ensures Elliam
                    remotely keeps an eye on safety compliance through image
                    recording.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Recycling, waste and noise pollution management targets met.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Activities</h4>
                <br />
                <ul>
                  <li className="fs-17">Recycling- roof tiles recycled.</li>
                  <li className="fs-17">Waste loads separated and removed</li>
                  <li className="fs-17">
                    Noise pollution- managed community expectations through
                    signage and advanced warnings of work-related noise periods,
                    working hours were restricted from 7am to 5pm to reduce
                    noise exposure.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project1ContentDetails;
