import React from "react";

import OwlCarousel from "react-owl-carousel";

import PeopleCard from "./people-card";

import Image1 from "../assets/images/h5-1.png";
import Image2 from "../assets/images/h5-2.png";
import Image3 from "../assets/images/project4-22.png";

const People = () => {
  return (
    <section className="home-s-6">
      <div className="container-fluid">
        <div className="container">
          <div className="row mb-4">
            <div className="col-12">
              <span className="fw-500">The heart of everything we do</span>
              <h2 className="fw-600 fs-40 color-dark-blue">
                Our people first ethos
              </h2>
            </div>
          </div>
          <div className="row desktop-screen">
            <div className="row">
              <OwlCarousel
                className="owl-carousel owl-theme"
                loop
                items={2}
                margin={24}
                dots={false}
                autoplay={true}
                autoplayTimeout={2500}
              >
                <PeopleCard
                  image={Image3}
                  heading="Communities we serve"
                  description="Elliam has a long history of working within vulnerable communities. Our policies are designed to  safeguard community well-being while  ensuring minimal disruption to daily living during construction periods. We use technology in our work practices to significantly reduce community risk."
                  linkLabel="COMMUNITY CASE STUDY"
                  link="/project4-details"
                />
                <PeopleCard
                  image={Image1}
                  heading="Our people"
                  description="Elliam’s team have been recruited and managed to work with our People First ethos, underpinned by our values of: safety, respect, sustainability and integrity.
              Each employee understands how the People First ethos and values are practically applied within their individual roles and responsibilities."
                  linkLabel="MEET OUR TEAM"
                  link="/about"
                />
                <PeopleCard
                  image={Image2}
                  heading="Your people"
                  description="At Elliam, we are committed to quality outcomes for the community through a better built environment. To make the process of your people managing our tasks easier and more accurate, we can link your team to our project management system including visual documentation of complete tasks and sign off on WHS compliance."
                  linkLabel="LEARN MORE"
                  link="/about"
                />
              </OwlCarousel>
            </div>
          </div>
          <div className="tablet-s">
            <div className="row">
              <OwlCarousel
                className="owl-carousel owl-theme"
                loop
                items={1}
                margin={24}
                dots={false}
                autoplay={true}
                autoplayTimeout={2500}
              >
                <PeopleCard
                  image={Image3}
                  heading="COMMUNITIES WE SERVE"
                  description="Elliam has a long history of working within vulnerable communities. Our policies are designed to  safeguard community well-being while  ensuring minimal disruption to daily living during construction periods. We use technology in our work practices to significantly reduce community risk."
                  linkLabel="COMMUNITY CASE STUDY"
                  link="/project4-details"
                />
                <PeopleCard
                  image={Image1}
                  heading="Our people"
                  description="Elliam’s team have been recruited and managed to work with our People First ethos, underpinned by our values of: safety, respect, sustainability and integrity.
              Each employee understands how the People First ethos and values are practically applied within their individual roles and responsibilities."
                  linkLabel="MEET OUR TEAM"
                  link="/about"
                />
                <PeopleCard
                  image={Image2}
                  heading="Your people"
                  description="At Elliam, we are committed to quality outcomes for the community through a better built environment. To make the process of your people managing our tasks easier and more accurate, we can link your team to our project management system including visual documentation of complete tasks and sign off on WHS compliance."
                  linkLabel="LEARN MORE"
                  link="/about"
                />
              </OwlCarousel>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default People;
