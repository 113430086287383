import React from "react";

import NewsHero from "../components/news-hero";
import NewsListing from "../components/news-listing";
import ProjectsSection from "../components/shared/projects-section";
import Footer from "../components/shared/footer";

const News = () => {
  return (
    <>
      <NewsHero />
      <NewsListing />
      <ProjectsSection />
      <Footer />
    </>
  );
};

export default News;
