import React from "react";

import { Outlet } from "react-router-dom";

import ProjectsHero from "../components/projects-hero";
import AboutProjects from "../components/about-projects";
import CaseStudies from "../components/case-studies";
import ProjectsDirectory from "../components/projects-directory";
import Footer from "../components/shared/footer";

const Projects = () => {
  return (
    <>
      <ProjectsHero />
      <AboutProjects />
      <CaseStudies />
      <ProjectsDirectory />
      <Footer />
      <Outlet />
    </>
  );
};

export default Projects;
