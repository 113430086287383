import React from "react";

import team from "../assets/images/s2-1.png";
import partner from "../assets/images/s2-2.png";

const AboutCapabilities = () => {
  return (
    <section className="about-s-2">
      <div className="container-fluid">
        <div className="container">
          <div className="row d-flex align-items-center mb-5">
            <div className="col-md-6">
              <img src={team} className="img-fluid w-100 mb-3" alt="Team" />
            </div>
            <div className="col-md-6 px-5 no-px-tab px-tab-20">
              <h2 className="fw-600">Quality spaces built on trust</h2>
              <p className="fs-18 fw-400">
                Elliam is focused on delivering high quality and on-time project
                outcomes. Our work is located New South Wales wide and
                concentrated in the Sydney Wollongong regions.
              </p>
              <p className="fs-18 fw-400">
                Over the last 20 years of operation, Elliam has developed an
                exceptional reputation with clients who are committed to
                providing quality buildings to the communities they serve.
              </p>
            </div>
          </div>
          <div className="row d-flex align-items-center mb-4">
            <div className="col-md-6 px-5 no-px-tab px-tab-20">
              <h2 className="fw-600">Growth built on trust</h2>
              <p className="fs-18 fw-400">
                95% of our work is either ongoing, repeat business or
                professional recommendation. We believe our success is a
                combination of our values and our capabilities, allowing us to
                meet the needs of the community, as much as spaces we build.
                <br />
                <br />
                It is the trust we build, that has allowed Elliam grow by
                diversifying across sectors, providing integrated solutions and
                developing deep levels of expertise in areas such as aged care
                and heritage.
              </p>
            </div>
            <div className="col-md-6">
              <img src={partner} className="img-fluid w-100" alt="Partner" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutCapabilities;
