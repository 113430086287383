import React, { useState, useEffect } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";

import ScrollToTop from "./components/scroll-to-top";
import Layout from "./components/Layout";
import Home from "./pages/home";
import About from "./pages/about";
import Projects from "./pages/projects";
import ProjectList from "./pages/project-list";
import Project1Details from "./pages/project1-details";
import Project2Details from "./pages/project2-details";
import Project3Details from "./pages/project3-details";
import Project4Details from "./pages/project4-details";
import Project5Details from "./pages/project5-details";
import Project6Details from "./pages/project6-details";
import Capabilities from "./pages/capabilities";
import News from "./pages/news";
import News1Details from "./pages/news1-details";
import News2Details from "./pages/news2-details";
import News3Details from "./pages/news3-details";
import News4Details from "./pages/news4-details";
import News5Details from "./pages/news5-details";
import News6Details from "./pages/news6-details";
import News7Details from "./pages/news7-details";
import ContactUs from "./pages/contactus";
import CookiesPopup from "./components/cookies-popup";

import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import ManlyPacific from "./pages/ManlyPacific";
import BasementWaterproofing from "./pages/BasementWaterproofing";

function App() {
  const [cookies, setCookies] = useState(localStorage.getItem("Cookies"));

  const handleCookies = () => {
    setCookies(true);
  };

  useEffect(() => {
    localStorage.setItem("Cookies", cookies);
  }, [cookies]);
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="projects" element={<Projects />} />
            <Route path="projects-listing" element={<ProjectList />} />
            <Route path="project1-details" element={<Project1Details />} />
            <Route path="project2-details" element={<Project2Details />} />
            <Route path="project3-details" element={<Project3Details />} />
            <Route path="project4-details" element={<Project4Details />} />
            <Route path="project5-details" element={<Project5Details />} />
            <Route path="project6-details" element={<Project6Details />} />
            <Route path="capabilities" element={<Capabilities />} />
            <Route path="news" element={<News />} />
            <Route path="news-01" element={<News1Details />} />
            <Route path="news-02" element={<News2Details />} />
            <Route path="news-03" element={<News3Details />} />
            <Route path="news-04" element={<News4Details />} />
            <Route path="news-refurbishment-lifecycle-extension" element={<News5Details />} />
            <Route path="news-building-memorable-experiences" element={<News6Details />} />
            <Route path="news-safety-hazard-reduction" element={<News7Details />} />
           
            <Route path="contact" element={<ContactUs />} />
            <Route
              path="featured-projects/manly-pacific"
              element={<ManlyPacific />}
            />
            <Route
              path="featured-projects/rockdale-strata"
              element={<BasementWaterproofing />}
            />
            <Route
              path="featured-projects/willoughby-council"
              element={<Project1Details />}
            />
            <Route
              path="featured-projects/school-department"
              element={<Project2Details />}
            />
            <Route
              path="featured-projects/housing-department"
              element={<Project5Details />}
            />
            <Route
              path="featured-projects/uniting-care"
              element={<Project3Details />}
            />
            <Route
              path="featured-projects/southern-cross-care"
              element={<Project4Details />}
            />
             <Route
              path="featured-projects/merryland’s-library-recladding"
              element={<Project6Details />}
            />
          </Routes>
          {cookies ? "" : <CookiesPopup handleCookies={handleCookies} />}
          </Layout>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
