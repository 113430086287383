import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage2 from "../assets/images/project5-2.jpg";

const Project5ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Annual Total Contract Value</b> &nbsp;
                  $5 to 7 million
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Individual Project Size</b> &nbsp; $100
                  to $1,000,000
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Contract Period</b> &nbsp;2015 to
                  present
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp;
                  maintenance and refurbishment of Department of Housing
                  residences. Elliam reports every 5 days on work delivered and
                  have a full performance review every 2 quarters.
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700"> Project Activities Include</b> &nbsp;
                  Refurbishment and repair of residences and housing
                  modifications for disability needs.
                </p>
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Fully meeting the compliance standards of Department of
                    Housing rules and regulations.
                  </li>
                  <li className="fs-17">
                    Meeting and exceeding the required jobs accepted and
                    completed on time.
                  </li>
                  <li className="fs-17">
                    Best practice defect rate performance across all work.
                  </li>
                  <li className="fs-17">
                    Full implementation of the occupational therapist’s
                    guidelines for disability modifications.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Project Management</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Our Project Management provides Department of Housing access
                    to records of start and completion dates.
                  </li>
                  <li className="fs-17">
                    Images of completed work are posted at the end of tasks.
                  </li>
                  <li className="fs-17">
                    Project Managers compliance to Department of Housing
                    extensive specifications.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Regular weekly team meetings for safety briefings and
                    tracking.
                  </li>
                  <li className="fs-17">
                    System generates immediate C alerts when tenants may have
                    Covid, with ‘do not enter’ instructions to team.
                  </li>
                  <li className="fs-17">
                    Manage safe work practices when tenants may have grievances
                    that could impact workflow and worker well-being.
                  </li>
                  <li className="fs-17">
                    Effective asbestos detection and safe removal.
                  </li>
                  <li className="fs-17">
                    Property protection through before and after photographs to
                    demonstrate property did not sustain any damage from the
                    work.
                  </li>
                  <li className="fs-17">
                    Trip and slip prevention practices for workers and
                    residents.
                  </li>
                  <li className="fs-17">
                    Property protection through before and after photographs to
                    demonstrate property did not sustain any damage from the
                    work.
                  </li>
                </ul>
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Monthly declarations on materials used to demonstrate
                    compliance with Department of Housing recycling regulations.
                  </li>
                  <li className="fs-17">
                    Risk assessment and safeguarding of pet companions of
                    tenants.
                  </li>
                  <li className="fs-17">
                    Hygienist to measure air quality and provide clearance
                    certificate on job completion.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project5ContentDetails;
