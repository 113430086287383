import React from "react";
import Header from "./shared/header";

const Project2DetailHero = () => {
  return (
    <div className="main-breadcrum project-details-bg">
      <div className="container-fluid no-px-tab">
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">Wollongong</span>
                </div>
                <div className="col-md-12 col-lg-7 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    Department of schools Primary and High
                  </h1>
                </div>
                <div className="col-md-6 mb-5">
                  <p className="text-white fs-18 fw-400">MAINTENANCE</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Project2DetailHero;
