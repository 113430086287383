import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/manlyPacific/1.webp";
import ProjectImage2 from "../assets/images/manlyPacific/2.webp";

const ManlyPacificDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Total Contract Value</b> &nbsp;
                  $1,300,000 annual project
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Individual Project Value</b> &nbsp; Two
                  projects valued at $600,000 and $720,000
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Commencement</b> &nbsp; 2022
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp;
                  Renovation and refurbishment of the Premium Function Room and
                  Roof top pool and surrounds. Elliam’s experienced project
                  management team allowed the hotel to complete a successful
                  construction with minimum interruption to guest services.
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Activities</b> &nbsp; Activities
                  include the demolition of the roof top pool, waterproofing in
                  and around the pool, balustrades instal and pool
                  refurbishment. An additional project was the refurbishment of
                  the Premium Function Room.
                </p>
                <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Fast turn-around to enable the function/bar room and pool to
                    be fully operational by the Christmas period.
                  </li>
                  <li className="fs-17">
                    Both projects required a number of speciality trades such as
                    waterproofing, tiling, historic refurbishment.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">System</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    The Project Management system allowed two teams to operate
                    for extended work hours.
                  </li>
                  <li className="fs-17">
                    Projects were completed within a short timeframe to ensure
                    Hotel would be fully operational by Christmas season
                  </li>
                  <li className="fs-17">
                    Project managing the delivery of specialised trade services
                    including: air conditioning, architectural specifications,
                    tiling, fireplace restoration and the installation of
                    extensive electrical systems
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Working at heights, the use of cranes to access rooftop
                  </li>
                  {/* <li className="fs-17">
                    Elliam successfully managed a Covid Safe workspace in full
                    compliance to regulations.
                  </li> */}
                </ul>
                <br />
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Noise pollution needed to be managed to minimise impact on
                    guests
                  </li>
                  <li className="fs-17">
                    Privacy screens ensured areas under construction were
                    shielded from public view
                  </li>
                  <li className="fs-17">
                    Liaison with the hotel staff allowed construction to take
                    place in and around the peak operating hours to minimise
                    impact on trade
                  </li>
                </ul>
                <br />
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default ManlyPacificDetails;
