import React, { useState } from "react";
import { Link } from "react-router-dom";

const Culture = () => {
  const [show1, setShow1] = useState(false);
  const [show2, setShow2] = useState(false);
  const [show3, setShow3] = useState(false);
  const [show4, setShow4] = useState(false);

  const handleReadmore1 = () => {
    setShow1(!show1);
  };
  const handleReadmore2 = () => {
    setShow2(!show2);
  };
  const handleReadmore3 = () => {
    setShow3(!show3);
  };
  const handleReadmore4 = () => {
    setShow4(!show4);
  };
  return (
    <>
      <section className="about-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-md-12 col-lg-4 pt-5 pb-4">
                <h1 className="text-white h-s3-h1 fw-700">
                  A culture of excellence
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-md-8 pb-5">
                <p className="fs-18 fw-400 text-white">
                  Elliam’s culture of excellence is founded on our
                  organisational values of safety, integrity, respect and
                  sustainability. Our project managers are our safety leaders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about-s-4 mb-5 mt-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="about-s4-card p-2">
                  <div className="about-h4-card-body">
                    <h4 className="fw-600">Safety</h4>
                    <p className="fs-16 fw-400">
                      Elliam Project Manager– Safety Leader. “By continually
                      engaging our people in good safety practices we have been
                      able to provide a safer working environment on all
                      measures, reducing, and in most cases, eliminating; body
                      stress, falls, hits from moving objects, vehicle collision
                      and contact with electricity.”
                    </p>
                    {show1 ? (
                      <>
                        <p className="fs-16 fw-400">
                          Safety First, is front and centre of all our work
                          activities. At Elliam, safety is not just about
                          managing risks but an employee mindset through ongoing
                          education. Our safety guidelines and practices are
                          designed to protect our people, our clients and the
                          communities they serve.
                        </p>
                        <p className="fs-16 fw-400">
                          We recognise no project has the same safety-risk
                          profiles. Before we commence each project, an
                          individualised risk assessment is conducted, and a
                          customised safety solution is developed and integrated
                          into project management.
                          <br />
                          <Link to="/news-03">
                            Read about Elliam's high safety standards
                          </Link>
                        </p>
                      </>
                    ) : (
                      ""
                    )}
                    <button
                      className="text-dark fs-16 fw-600"
                      onClick={handleReadmore1}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      {show1 ? "Show Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="about-s4-card p-2">
                  <div className="about-h4-card-body">
                    <h4 className="fw-600">Integrity</h4>
                    <p className="fs-16 fw-400">
                      Elliam Project Manager. “Our clients rely on Elliam to
                      keep our promises and deliver quality work on time. Our
                      exceptional planning, organisations skills, communication
                      and ongoing remote and onsite monitoring, has enabled
                      Elliam to achieve best in class defect rates on our work.”
                    </p>
                    {show2 ? (
                      <p className="fs-16 fw-400">
                        At Elliam, we value the integrity of clear communication
                        and strong compliance to the client brief. We achieve
                        successful outcomes for stakeholders by working closely
                        with clients and architects.
                      </p>
                    ) : (
                      <>
                        <br />
                      </>
                    )}

                    <button
                      className="text-dark fs-16 fw-600"
                      onClick={handleReadmore2}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      {show2 ? "Show Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="about-s4-card p-2">
                  <div className="about-h4-card-body">
                    <h4 className="fw-600">Respect</h4>
                    <p className="fs-16 fw-400">
                      “ Our clients trust Elliam with the privacy of their
                      tenants. Every worker is trained on our privacy
                      guidelines, meaning tenants are assured that access is
                      contained to work areas only and household items are left
                      undisturbed.”
                    </p>
                    {show3 ? (
                      <>
                        <p className="fs-16 fw-400">
                          Elliam is an organisation which respects diversity,
                          working across the spectrum of age, public and private
                          clients, we have a specialised workforce who are
                          trained to understand the needs of our clients and
                          their communities.
                        </p>
                        <p className="fs-16 fw-400">
                          Elliam has a long history working with sectors that
                          serve some of our most vulnerable such as the
                          department of housing, education, aged care and
                          councils. Our team is highly experienced in
                          construction within and alongside residential
                          communities, we plan the least amount of disruption to
                          existing pedestrian, user and traffic arrangements and
                          provide on time delivery for the return to normal
                          life.
                        </p>
                      </>
                    ) : (
                      <>
                        <br />
                        <br />
                        <br />
                        <br />
                      </>
                    )}

                    <button
                      className="text-dark fs-16 fw-600"
                      onClick={handleReadmore3}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      {show3 ? "Show Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="about-s4-card p-2">
                  <div className="about-h4-card-body">
                    <h4 className="fw-600">Sustainability</h4>
                    <p className="fs-16 fw-400 mb-0">
                      “Elliam is geared up to meet the increasing demand for
                      green solutions. We have a strong track record recycling
                      construction waste and using innovative recycled
                      materials.” Wayne Dreis Managing Director. Elliam’s green
                      solutions for buildings target many standards including
                      but not limited to:
                    </p>
                    {show4 ? (
                      <>
                        <ul className="fs-16 fw-400">
                          <li>
                            Indoor air pollution, reducing of over 90% of
                            volatile organic compound materials.
                          </li>
                          <li>
                            Use of sustainable building materials, such as
                            innovative recycled materials
                          </li>
                          <li>
                            Adherence to sustainable construction guidelines,
                            with over 90% of our construction waste recycled.
                          </li>
                        </ul>
                      </>
                    ) : (
                      <>
                        <br />
                        <p></p>
                      </>
                    )}

                    <button
                      className="text-dark fs-16 fw-600"
                      onClick={handleReadmore4}
                      style={{ border: "none", backgroundColor: "transparent" }}
                    >
                      {show4 ? "Show Less" : "Read More"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Culture;
