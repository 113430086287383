import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/news1.jpeg";

const News1ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                  Australia generates more than 27 million tonnes of waste from
                  construction and demolition. At Elliam, we are committed to
                  recycling. As leaders in construction waste, Elliam have been
                  recycling for over a decade, recycling 85% of our construction
                  waste, compared to a national average of 76%.
                  <br />
                  <br />
                  Recycling waste material has immense paybacks, it reduces
                  costs, conserves resources and has a positive impact on the
                  environment. Our recycling strategy and guidelines, ensure
                  each site is evaluated for its recycling potential and optimum
                  recycling outcomes . Elliam’s construction waste is either
                  reused onsite, redistributed or recycled into new aggregate
                  products.
                </p>
                <h2 className="fw-600">Elliam’s recycling practices</h2>
                <br />
                <br />
                <h4 className="fw-600">Reuse of existing materials on site</h4>
                <p className="fw-400 fs-17 mb-4">
                  Elliam have a strong practice of using recycled and repurposed
                  elements in refurbishments and rebuilds, to both lower
                  transport costs and pollution.
                  <br />
                  <br />
                  Onsite reuse of material typically includes reuse of wooden
                  beams, stairs, doors, windows, roof tiles, plumbing fixtures
                  and electrical fittings, even corroded steele can be treated
                  and reused rather than replaced with new.
                  <br />
                  <br />
                  Elliam’s heritage team ensure that materials with rich
                  heritage value are reused in applications that preserve their
                  heritage. Our extensive experience with heritage materials
                  allows Elliam to reuse older materials without compromising
                  structural integrity.
                </p>
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage"
                />
                <h4 className="fw-600">
                  Recycling construction waste into aggregates{" "}
                </h4>
                <p className="fw-400 fs-17 mb-4">
                  Construction waste that cannot be directly reused on site can
                  be recycled as aggregates. Recycled aggregate products
                  include; metals, mineral wool, wood, bitumen, bricks,
                  cardboards and concretes.
                  <br />
                  <br />
                  On site, Elliam collects, sorts and later distributes waste to
                  appropriate recycling entities. Waste materials Elliam
                  distributes for recycling into aggregates or for reuse off
                  site include:
                </p>
                <ul>
                  <li>
                    Timber- Leftover timbers are used on other construction
                    sites or sold for firewood or mulch.
                  </li>
                  <li>
                    Plasterboard - Plasterboard is used as plant nutrients or
                    recycled back into ‘as new” plasterboard.
                  </li>
                  <li>
                    Bricks- Bricks last over 200 years and can be recycled in
                    other construction projects or crushed into powder and used
                    in the manufacture of new roads or bricks.
                  </li>
                  <li>
                    Copper, Steel and Aluminium –can be stripped and reshaped
                    for new building applications and products.
                  </li>
                  <li>
                    Glass- Recycled glass uses much less energy in the
                    manufacturing process than new glass, and reduces impacts on
                    air pollution by 20% and water pollution by 50%.
                  </li>
                  <li>
                    Plastics- Increasingly plastics can be recycled into a range
                    of ‘as new’ materials.
                  </li>
                </ul>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Every Elliam project is completed with a full report of recycling outcomes. For more information on Elliam recycling or a copy of our current guidelines, contact{" "} 
                  <a href="mailto: admin@elliam.com.au">admin@elliam.com.au</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News1ContentDetails;
