import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";

const NewsCard = ({ date, title, slug}) => {
  return (
    <div className="col-lg-4 mb-4">
      <div
        className="bg-white px-3 py-4"
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <span className="fw-500 fs-17">{date}</span>
          <h4 className="fw-600">{title}</h4>
        </div>
        <Link
          to={`/news-${slug}`}
          className="fw-600 fs-20 color-dark-blue d-flex flex-row align-items-center"
        >
          Read More &nbsp;
          <FontAwesomeIcon icon={faArrowRight} style={{ marginTop: "5px" }} />
        </Link>
      </div>
    </div>
  );
};

export default NewsCard;
