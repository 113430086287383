import React from "react";
import BasementWaterproovingHero from "../../components/basement-waterprooving";
import BasementWaterproovingDetails from "../../components/basement-waterprooving-details";
import Footer from "../../components/shared/footer";
import { useLocation } from "react-router-dom";

const BasementWaterproofing = () => {
  const {pathname} = useLocation();
  console.log('pathname :>> ', pathname);
  return (
    <>
      <BasementWaterproovingHero />
      <BasementWaterproovingDetails />
      <Footer />
    </>
  );
};

export default BasementWaterproofing;
