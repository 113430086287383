import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/news2.jpg";

const NewsContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                  Elliam’s long history in heritage refurbishment breathes new
                  life into heritage buildings, modernising the amenities and
                  lifestyle of occupants, while preserving heritage values.
                  <br />
                  <br />
                  Elliam’s decades of heritage work includes buildings dating
                  back to the late 1800’s. Combining the latest building
                  techniques with traditional workmanship on heritage structures
                  and facades, Elliam creates built environments that
                  effectively blend the best of traditional and modern.
                  <br />
                  <br />
                  Since 2008, Elliam has refurbished over 200 heritage
                  buildings, notable inclusions are: Wollongong Public School
                  and the Department of Housing Estates in Sydney’s inner city.
                  Our heritage refurbishment portfolio spans Victorian,
                  Federation and Art Deco architectural periods.
                  <br />
                  <br />
                  <img
                    src={NewsImage}
                    className="img-fluid w-100 mb-4"
                    alt="NewsImage"
                  />
                  <br />
                  <br />
                  Elliam’s refurbishment solutions are designed to enhance air,
                  light, water and amenities. We bring older buildings into line
                  with modern expectations through structural changes such as
                  glass roofs to bring light into interiors and interior stairs
                  to improve internal accessibility.
                  <br />
                  <br />
                  Our diverse team of heritage experts meet the NSW Heritage
                  Office’s standards and guidelines identified for heritage
                  buildings which cover: site, structure, masonry, metal,
                  roofing, timber, paint, damp and ventilation and maintenance.
                  <br />
                  <br />
                  Our heritage team’s comprehensive set of skills include;
                </p>
                <ul>
                  <li>
                    The preservation of structural elements of significant
                    heritage value such as windows and doors, which are
                    incorporated into new structural builds.
                  </li>
                  <li>
                    The restoration of older masonry and brickwork to return
                    buildings to their former glory.
                  </li>
                  <li>
                    Ensuring heritage structures are structurally sound and
                    compliant with current building codes.
                  </li>
                  <li>
                    Bringing historic buildings into compliance with indoor air
                    climate targets and energy consumption goals.
                  </li>
                </ul>
                <br />
                <p className="fw-400 fs-17 mb-4">
                For more information on Elliam’s heritage refurbishment and restoration services contact{" "}
                  <a href="mailto:admin@elliam.com.au">admin@elliam.com.au</a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default NewsContentDetails;
