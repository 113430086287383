import React from "react";

import AboutHero from "../components/about-hero";
import AboutDetailed from "../components/about-detailed";
import Culture from "../components/culture";
import Footer from "../components/shared/footer";

const About = () => {
  return (
    <>
      <AboutHero />
      <AboutDetailed />
      <Culture />
      <Footer />
    </>
  );
};

export default About;
