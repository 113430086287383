import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/new333.jpg";
import NewsImage2 from "../assets/images/news4.jpg";

const News3ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                  High safety standards are critical to worker health, the
                  majority of the 8,000 construction injuries in NSW each year,
                  could have been prevented with the right safety practice. At
                  Elliam, we are committed to worker safety, our rigorous safety
                  standards, mean our safety track record is one of the best in
                  the industry.
                  <br />
                  <br />
                  To fully integrate safety into day-to-day work practices,
                  Elliam’s Project Managers also work in the role of Safety
                  Managers, applying a comprehensive approach to safety
                  practices including best practice training, tools and
                  policies. This approach includes safety management, safety
                  education, worker well-being, skill specific safety, and more
                  effective safety management through a hierarchy of control.
                </p>
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage"
                />
                <h2 className="fw-600">
                  Elliam’s safety practices, tools and policies
                </h2>
                <br />
                <br />
                <p className="fw-400 fs-17 mb-4">
                  Elliam’s safety practices, tools and policies are designed to
                  optimise risks mitigation. We conduct regular safety audits
                  which assess, report and manage any possible risks
                </p>
                <br />
                <h4 className="fw-600">Safety Management</h4>
                <br />
                <ul>
                  <li>
                    Effective safety management - Safety Managers supervise
                    workers to ensure each worker adheres to proper safety
                    procedures. Elliam’s Safety Managers achieve better safety
                    outcomes by integrating direct safety instruction into each
                    worker’s daily goals and activities. This instruction
                    typically includes hazard identification and assessment,
                    prevention, and control.
                  </li>
                  <li>
                    Preparatory safety briefings - It is the responsibility of
                    every Elliam’s Safety Manager to ensure that each worker is
                    informed of the safety risks before they set foot on a
                    specific construction site.
                  </li>
                  <li>
                    Safety plans personalised to worker needs – Ellliam’s
                    workers refresh their safety knowledge with regular training
                    based upon a personalised safety education plan, which
                    recognises their level of experience and specific skillset.
                  </li>
                  <li>
                    Safety Documentation – To oversee site safety, Elliam
                    rigorously documents all proposed safety practices to be
                    implemented on the site. This is updated as safety needs
                    change across the project’s lifespan. A safe work method
                    statement SWMS is prepared for all high-risk construction
                    projects before work commences.
                  </li>
                  <li>
                    Safety related registrations and licenses – Safety Managers
                    ensure all proper registrations and licenses are in place
                    before work begins. Appropriate certification is validated
                    in advance for high-risk tasks such as blasting.
                  </li>
                  <li>
                    Site signage – The site SWMS is clearly displayed at the
                    site, including a dedicated 24-hour emergency contact. Site
                    signage also includes maps and directional signage, pointing
                    workers and site visitors to amenities and safety equipment
                    such as first aid and fire.
                  </li>
                  <li>
                    Heavy machinery access – For the safety of workers and
                    visitors, heavy vehicles are allocated separate entry and
                    exists.
                  </li>
                  <li>
                    Chemical storage - All chemicals are stored to guard against
                    fires, explosions, pollution and injury. Purpose built
                    chemical storage units protect against spillage and
                    explosions.
                  </li>
                  <li>
                    Extreme weather risks – Safety plans include provisions for
                    extreme weather, with emergency measures to secure the site
                    and when necessary, instructions for workers to stop work.
                  </li>
                  <li>
                    Asbestos and Lead management – Safety Managers are educated
                    in the risks of ACM, Asbestos Containing Materials and Lead,
                    recognising and avoiding dangers and compliance to
                    regulations when materials are required to be removed.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Safety education</h4>
                <br />
                <ul>
                  <li>
                    Common hazards - Elliam’s employees are educated on common
                    hazards such as falls, caught-in or between, struck-by
                    objects and electrocution risks. Particular attention is
                    paid to the most common source of construction site injuries
                    which include; slips, trips, falls, lifting and handling.
                  </li>
                  <li>
                    New employee safety - Each new Elliam employee is assessed
                    on their existing and required safety knowledge and is
                    educated to a required level before work commences.
                  </li>
                  <li>
                    Site specific - Elliam education addresses specific risks
                    for each construction site.
                  </li>
                  <li>
                    Changing conditions - Elliam’s safety education addresses
                    changing conditions detected by ongoing safety monitoring on
                    the site.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={NewsImage2}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage2"
                />
                <br />
                <br />
                <h4 className="fw-600">Worker Well-Being </h4>
                <br />
                <ul>
                  <li>
                    Mental health awareness - Construction workers have high
                    incidences of mental health issues including suicide and
                    depression. The health and safety risks associated with
                    mental health issues are managed through Elliam’s
                    confidential channels of communication and through access to
                    employee wellness programs.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Skill specific safety </h4>
                <br />
                <ul>
                  <li>
                    Skill specific - In addition to general safety risks, the
                    risks associated with each trade are identified and
                    incorporated into education programs and day-to-day
                    management.
                  </li>
                  <li>
                    Task specific - Each worker is made aware of the safety
                    hazards of the specific work to be undertaken at a site.
                    Every project is assigned a dedicated safety manager who
                    will map out the risks by task, to ensure that timely and
                    specific education is given before the commencement of a
                    specific task.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">Hierarchy of safety controls </h4>
                <br />
                <ul>
                  <li>
                    Elimination of hazards - The first line of defence is the
                    elimination of hazards where possible. Elimination of
                    hazards is both conducted prior to the commencement of the
                    construction process and during the project, to address
                    changing hazard conditions.
                  </li>
                  <li>
                    Personal Protection Equipment - Personal protection
                    equipment is regularly replenished to maintain safety
                    standards. PPE’s comply with Safe Work Australia guidelines
                    and include high vis vests, goggles and harnesses. Safety
                    Managers ensure all Elliam workers are trained in best
                    practice use of personal protection equipment.
                  </li>
                </ul>
                <br />
                <br />
                <p className="fw-400 fs-17 mb-4">
                  To find out more about Elliam’s safey practices and policies
                  contact us on{" "}
                  <a href="mailto: admin@elliam.com.au ">
                    {" "}
                    admin@elliam.com.au{" "}
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News3ContentDetails;
