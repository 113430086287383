import React from "react";

import employee from "../assets/images/s2.png";

const AboutDetailed = () => {
  return (
    <section className="about-s-2">
      <div className="container-fluid">
        <div className="container">
          <div className="row d-flex align-items-center">
            <div className="col-md-12 col-lg-6 mb-3">
              <img src={employee} className="img-fluid w-100" alt="" />
            </div>
            <div className="col-md-12 col-lg-6 px-5 no-px-tab px-tab-20">
              <h2 className="fw-600">
                High performance through Elliam’s cloud project management
                platform
              </h2>
              <p className="fs-18 fw-400">
                Elliam’s project management technology is best in class,
                allowing us to analyse individual workflows and tasks against
                each project management objective. Our platform has improved
                Elliam’s performance on all leading project indicators including
                health and safety, cost control, operations and asset
                management, solving problems and providing informed insights for
                future activities.
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-6 px-tab-20">
              <p className="fs-18 fw-400">
                Every Elliam employee is connected 24/7 to our cloud-based
                project management platform using Elliam's mobile app. Clients
                are also granted access to milestone tracking within the
                platform.
              </p>
              <p className="fs-18 fw-400">
                "The delivery of all projects are carefully programmed and
                managed in our project management technology, including work
                methodology and constant checking of progress milestones. Our
                technology allows us to accelerate project tasks as needs arise,
                by the addition of resources and the optimisation of the
                controlling processes. We use an enterprise-wide data driven
                dashboard to give us the ability to remotely manage projects
                24/7."
              </p>
              <p className="fs-18 fw-400">
                Through our platform we have full visibility of project tasks,
                providing an excellent method of tracking responsibilities and
                small task targets, as the project progresses. Elliam’s project
                managers are able to predict and avoid critical incidents and
                action an immediate response where required.
              </p>
              <p className="fs-18 fw-400">
                As a construction company heavily invested in technology, Elliam
                has continued to outperform our competitors delivering better
                cost control, bid accuracy, and build efficiency.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutDetailed;
