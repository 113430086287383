import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";
import NewsImage3 from "../assets/images/news6-01.webp";
import NewsImage from "../assets/images/news6-02.webp";
import NewsImage2 from "../assets/images/news6-03.webp";
import NewsImage4 from "../assets/images/news6-04.webp";
import NewsImage5 from "../assets/images/news6-05.webp";
import NewsImage6 from "../assets/images/news6-06.webp";
import NewsImage7 from "../assets/images/news6-07.webp";

const News5ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
         
                 <h2 className="fw-600"> Elliam’s impressive portfolio includes some of Sydney’s most iconic Hotel Fit-Outs. <span className="fw-600 fs-24">Wayne Dreis</span> </h2>
          
             
              <br/>
              <br/>
                <p className="fw-400 fs-17 mb-4">
                Hotel fit outs in Sydney focus on the both the visual appeal and the overall guest experience. Elliam has fitted out of some of Sydney’s most iconic hotel, both preserving the original character of the hotel while creating lasting impressions.​
                  </p>
                 
                  <img
                  src={NewsImage3}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage3"
                />
                 <br />
                 <br />
                <p className="fw-400 fs-17 mb-4">Elliam transformed the rooftop and Premium Bar of the Manly Pacific Hotel.</p>
                <br />
                <br />
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage3"
                /> 
                 <br />           
                <p className="fw-400 fs-17 mb-4">Elliam’s  hotel fit outs includes the ‘Four in Hand’s  Hatted Chef Dining Room.</p>

                <h2 className="fw-600">Balancing Tradition and Innovation</h2>
                <br />
                <p className="fw-400 fs-17 mb-4">Sydney's historic hotels, such as the iconic QVB Hotel and Paddington’s legendary Four in Hand, have successfully blended tradition with modernity through thoughtful fit-outs.  Heritage Hotel Fit Outs require a multidisciplinary team that works with both heritage and cutting edge construction skills.</p>
                <br/>
                <br/>
                <img
                  src={NewsImage2}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage"
                />
                <br />
                <p className="fw-400 fs-17 mb-4">Barangaroo precinct hosts some of Sydney’s most contemporary hotel fit outs</p>
                <h2 className="fw-600">Embracing Contemporary Elegance</h2>
                <br />
                <p className="fw-400 fs-17 mb-4">
                On the flip side, Sydney's modern hotels, like the sleek establishments in Barangaroo, demand fit-outs that exude contemporary elegance. These spaces often feature cutting-edge design elements, state-of-the-art technology, and a seamless integration of functionality and style. Hotel fit-outs in these establishments reflect the city's cosmopolitan vibe.
                </p>
                <h2 className="fw-600">The Role of Rooftop Transformations</h2>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Sydney's skyline is dotted with rooftop bars and pools, each vying to offer patrons a unique experience against the backdrop of the city's stunning landmarks. Hotel fit-outs extend beyond interior spaces to encompass these elevated realms, where guests can unwind, socialise, and soak in breathtaking views.
                </p>
                <br />
                <br/>
                <img
                  src={NewsImage4}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage4"
                />
                <br />
                <p className="fw-400 fs-17 mb-4">The Australian Hotel in the Rocks, one of the oldest hotels in the area, boasts a vibrant rooftop bar that is a must for New Year’s Eve fireworks.</p>
                <br/>
                <img
                  src={NewsImage5}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage5"
                />
                 <br />
                <p className="fw-400 fs-17 mb-4">Elliam’s refurbishment of the Manly Pacific rooftop and pool is a significant upgrade to their guest experience.</p>

                <h4 className="fw-600">Rooftop Pools: A Soaring Experience</h4>
                <p className="fw-400 fs-17 mb-4">
                The refurbishment of rooftop pools is a trend gaining momentum in Sydney. Hotels like The Intercontinental Sydney Double Bay have turned to innovative fit-outs to transform their rooftop pools into serene havens, offering guests an escape from the bustling city below. The interplay of water features, comfortable lounging areas, and panoramic views creates a sensory experience like no other. Many Pacific’s Rooftop transformation, by Elliam, is a stand out Rooftop experience which offers uninterrupted, glorious ocean views from inside the pool itself.
                </p>
                <br />
                <br/>
                <img
                  src={NewsImage6}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage6"
                />
                <br />
                <p className="fw-400 fs-17 mb-4">Elliam replaced the awning roof at Bar Brighton, retaining the ambience and structural integrity of the hotel​</p>
                <h4 className="fw-600">Sky-High Bars and Balconies</h4>
                <p className="fw-400 fs-17 mb-4">
                Sydney's skyline comes alive at night, and rooftop bars and balconies play a pivotal role in providing guests with a front-row seat to the spectacle. Hotel fit-outs for these spaces involve meticulous planning to ensure optimal use of the views, atmospheric lighting, and comfortable seating. The result? A perfect synergy between the hotel's identity and the cityscape. Hotels like the Clock  in Surry Hills and  Bar Brighton in Brighton Le Sands,  offer both panoramic views of the neighbourhood and the best spots for people watching.</p>
                <br/>
                <h2 className="fw-600">The Competitive Edge of Specialized Hotel Fit-Out Firms </h2>
                <p className="fw-400 fs-17 mb-4">
                As the demand for exquisite hotel experiences grows, the advantages of partnering with specialised hotel fit-out firm, such as Elliam, becomes increasingly apparent. Our track record in hotel fit outs is based on our experience in industry insights and trends, brand customisation and exceptional project management.</p>
                <br/>
                <h4 className="fw-600">Industry Insight and Trends</h4>
                <p className="fw-400 fs-17 mb-4">
                Specialised firms, like Sydney's own Elliam, are focused on the demands of the hospitality industry. Elliam’s Hotel Fit Out team understand the latest trends, guest expectations, and operational needs, ensuring that fit-outs align with the hotel's overall strategy.</p>
                <br/>
                <h4 className="fw-600">Customisation for Brand Identity</h4>
                <p className="fw-400 fs-17 mb-4">
                Hotels are more than just buildings; they are brands. Hotel fit-out specialists tailor their solutions to reflect and enhance the unique identity of each establishment, creating spaces that resonate with guests and contribute to the overall brand narrative.</p>
                <br/>
                <h4 className="fw-600">Seamless Project Management</h4>
                <p className="fw-400 fs-17 mb-4">
                Completing a hotel fit-out requires precise project management to minimise disruptions to guests and staff. Specialised firms, such as Elliam, equipped with experience in the intricacies of hospitality projects, ensure a fit out is conducted with minimal impact on hotel operating hours and guest experience.</p>
                <br/>
                <br />   
                <img
                  src={NewsImage7}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage7"
                />
                <br />
                <p className="fw-400 fs-17 mb-4">The Orient Hotel has charmed Sydney siders since the 1840’s. The first floor, on Mrs Jones terrace, offers the perfect vantage point to watch the bustle of life on the Rock’s cobbled laneways below.</p>
                <br/>
                <h2 className="fw-600">The Future of Sydney's Hotel Fit-Outs</h2>
                <p className="fw-400 fs-17 mb-4">
                As Sydney's skyline continues to evolve, so too will the art of hotel fit-outs.  Distinctive Sydney Hotel Fit outs marry the past with the future, preserving the character of Sydney hotels for generations to come.</p>
                <br/>
                <p className="fw-400 fs-17 mb-4">Sydney's hotels are not just places of hospitality, they are immersive experiences, and the architects of these experiences are the unsung heroes behind the scenes—crafting spaces that become an integral part of a visitor's memory of this dynamic city.</p>
                <br/>
                <br/>
                <p className="fw-400 fs-17 mb-4">To find out more on Elliam’s Hotel Fit Outs contact us on <a href="mailto:admin@elliam.com.au">admin@elliam.com.au</a></p>
              </div>
  
            </div>
          
          </div>
        </div>

      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.slice(0, 5).map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                 
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News5ContentDetails;
