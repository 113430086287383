import React from "react";
import { Link } from "react-router-dom";

import StatsCard from "./stats-card";

import {
  faHouseChimneyWindow,
  faTrophy,
  faUserGroup,
  faPeopleGroup,
} from "@fortawesome/free-solid-svg-icons";

const WhatWeDo = () => {
  return (
    <>
      <section className="home-s-4">
        <div className="container-fluid">
          <div className="container">
            <div className="row d-flex align-items-center">
              <div className="col-md-12 col-lg-6 mb-tab-30">
                <div className="row">
                  <StatsCard
                    number="400 +"
                    description="Successfully completed projects"
                    icon={faHouseChimneyWindow}
                  />
                  <StatsCard
                    number="20"
                    description="Years of experience in the construction industy"
                    icon={faTrophy}
                  />
                  <StatsCard
                    number="100 +"
                    description="Happy clients &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;"
                    icon={faUserGroup}
                  />
                  <StatsCard
                    number="30 +"
                    description="team of dedicated professionals"
                    icon={faPeopleGroup}
                  />
                </div>
              </div>
              <div className="col-md-12 col-lg-6 px-5 px-tab-20">
                <span className="fw-500">WHAT WE DO</span>
                <h2 className="fw-600 fs-40">Building at scale</h2>
                <p className="fw-500">
                  Quality project outcomes measured in community lifestyle
                  outcomes, on time delivery, safety and enduring builds.
                </p>
                <Link
                  to="/projects-listing"
                  className="yellow-btn"
                  style={{ textDecoration: "none", color: "black" }}
                >
                  SEE PROJECT LISTING
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default WhatWeDo;
