import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage1 from "../assets/images/pro2-1.webp";
import ProjectImage2 from "../assets/images/pro2-2.webp";

const Project6ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Job Duration</b> &nbsp;
                  20 weeks
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Challenges Overcome</b> &nbsp; Successful recladding with minimum disruption to library services and the surrounding community​
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Value</b> &nbsp;$ 470,000
                </p>
                <br />
                <br />
                <img
                  src={ProjectImage1}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <p className="fs-17">
                Merryland’s Library entrance
                </p>
                <br />
                <h2 className="fw-600">Outcome</h2>
                <br />
                <ul>
                  <li className="fs-17">
                  Successfully replaced deteriorating cladding with James Hardie Exo Tec System to improve building aesthetics and structural integrity.​
                  </li>
                  <li className="fs-17">
                  Coordinated with Council for timely meetings, site walkthroughs, and hold point inspections to ensure project progress and compliance.​
                  </li>
                  <li className="fs-17">
                  Implemented pedestrian and traffic management plans to ensure public safety and accessibility throughout the construction period.​
                  </li>
                  <li className="fs-17">
                  Completion of project within the stipulated 5-month timeframe, allowing the library to remain operational throughout the recladding process.​
                  </li>
                  <li className="fs-17">
                  Maintained courteous and respectful conduct of all workers, considering the high-profile nature of the site and its frequent visitors.​
                  </li>
                </ul>
                <br />
                <h2 className="fw-600">Systems</h2>
                <br />
                <ul>
                  <li className="fs-17">
                  Developed and adhered to a prestart checklist to ensure all necessary documentation and safety measures were in place before commencing work.​
                  </li>
                  <li className="fs-17">
                  Established hold points at key stages of the project, including cladding removal, wall preparation, window installation, and final inspection.
                  </li>
                  <li className="fs-17">
                  Implemented a comprehensive waste management plan to dispose of generated rubbish off-site responsibly.
                  </li>
                  <li className="fs-17">
                  Installed appropriate WHS signage and traffic control measures, including temporary fencing, to mitigate hazards and ensure public safety.​
                  </li>
                  <li className="fs-17">
                  Provided detailed methodology for keeping water out of the building during rain periods when cladding was temporarily removed.​
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <p className="fs-17">
                Merryland’s Library and adjacent Cumberland Council admin
                </p>
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <ul>
                  <li className="fs-17">
                  Conducted dilapidation reports to assess and mitigate risks to adjacent structures and surrounding areas.​
                  </li>
                  <li className="fs-17">
                  Fenced off work areas and provided clear access routes for pedestrians and emergency services to ensure public safety.​
                  </li>
                  <li className="fs-17">
                  Implemented comprehensive traffic control measures, including WHS signage and certified traffic controllers, to mitigate hazards related to vehicular traffic.​
                  </li>
                  <li className="fs-17">
                  Developed a methodology for managing construction hazards such as falling objects, electrical safety, and structural stability.​
                  </li>
                  <li className="fs-17">
                  Proactively addressed potential risks such as lead paint removal, ensuring adherence to safety protocols and regulations.​
                  </li>
                </ul>
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <ul>
                  <li className="fs-17">
                  Implemented noise reduction measures and dust control techniques to minimise disruption to nearby residents and maintain air quality.​
                  </li>
                  <li className="fs-17">
                  Coordinated with Council officers to ensure compliance with environmental regulations and standards, including waste management and recycling initiatives.​
                  </li>
                  <li className="fs-17">
                  Selected environmentally friendly construction methods and materials to minimize the project's carbon footprint.​
                  </li>
                  <li className="fs-17">
                  Minimized waste generation through responsible disposal practices and recycling efforts.​
                  </li>
                  <li className="fs-17">
                  Demonstrated commitment to sustainability by prioritising energy-efficient building practices and reducing environmental impacts throughout the project lifecycle.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project6ContentDetails;
