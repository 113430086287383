import React from "react";
import { Helmet } from "react-helmet-async";

import News1DetailHero from "../components/news1-detail-hero";
import News1ContentDetails from "../components/news1-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/news1.jpeg";

const News1Details = () => {
  return (
    <>
    <header>
     <Helmet>
        <meta property="og:title" content={data.news[0].title} />
        <meta property="og:description" content="Elliam, a construction industry leader in Australia, is making a substantial impact on waste reduction. In a country generating over 27 million tonnes of construction and demolition waste, Elliam stands out with its commitment to recycling. With more than a decade of experience, Elliam proudly recycles an impressive 85% of its construction waste, well above the national average of 76%. Our sustainable approach doesn't just save costs; it also conserves valuable resources and contributes positively to the environment. Our recycling strategy ensures that each construction site is meticulously assessed for its recycling potential, resulting in optimal recycling outcomes. Elliam's responsible practices encompass the reuse of existing materials right on the construction site. This includes repurposing wooden beams, stairs, doors, windows, roof tiles, plumbing fixtures, electrical fittings, and even reclaimed steel, minimizing transport costs and environmental impact. Furthermore, our heritage team specializes in preserving materials with rich historical significance, seamlessly integrating them into modern projects without compromising structural integrity. Join us in our mission to create a more sustainable and eco-friendly construction industry in Australia." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-01" />
      </Helmet>
      <News1DetailHero />
      </header>
      <News1ContentDetails />
      <Footer />
    </>
  );
};

export default News1Details;
