import React from "react";
import { Helmet } from "react-helmet-async";

import News3DetailHero from "../components/news3-detail-hero";
import News3ContentDetails from "../components/news3-content-details";
import Footer from "../components/shared/footer";
import data from "../content/news.json";
import NewsImage from "../assets/images/new333.jpg";

const NewsDetails = () => {
  return (
    <>
    <header>
      <Helmet>
        <meta property="og:title" content={data.news[2].title} />
        <meta property="og:description" content="At Elliam, we set the industry standard for safety in construction. With a firm commitment to worker well-being, our rigorous safety practices, comprehensive training, and vigilant safety management ensure that we prevent injuries and promote a secure working environment. Discover how Elliam goes above and beyond to make safety a top priority on every project." />
        <meta property="og:image" content={NewsImage} />
        <meta property="og:url" content="https://www.elliam.com.au/news-03" />
      </Helmet>
      <News3DetailHero />
      </header>
      <News3ContentDetails />
      <Footer />
    </>
  );
};

export default NewsDetails;
