import React from "react";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const ProjectListingCard = ({
  client,
  location,
  service,
  scope,
  time,
  value,
  description,
  image,
  slug,
}) => {
  const sanitizer = DOMPurify.sanitize;

  return (
    <>
      <div className="col-lg-4 col-md-6 mt-5">
        <div className="py-3">
          <div>
            <div className="fs-18">
              <div className="d-flex flex-row align-items-center">
                <b>Client: &nbsp;</b>
                <p className="fw-500 mb-0">{client}</p>
              </div>
              <div className="d-flex align-items-center mb-4">
                <b>Location: &nbsp;</b>
                <p className="fw-500 mb-0" dangerouslySetInnerHTML={{
                __html: sanitizer(location),
                }} />
              </div>
            </div>
            <img
              src={image}
              alt="Project"
              className="project-image"
              width="400"
              height="300"
            />
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 mt-5">
        <div className="py-3 ps-md-5">
          <div>
            <div className="fs-18">
              <b>Service:</b>
              <p>{service}</p>
            </div>
            <div className="fs-18">
              <b>Scope:</b>
              <p>{scope}</p>
            </div>
            <div className="fs-18">
              <b>Time:</b>
              <p>{time}</p>
            </div>
            {value && (
              <div className="fs-18">
                <b>Project Value:</b>
                <p>
                  {value.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                  })}
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0 mt-5">
        <div className="py-3">
          <div className="fs-18">
            <b>Description:</b>
            <p  dangerouslySetInnerHTML={{
              __html: sanitizer(description),
            }} />
          </div>
          {slug ? (
            <Link
              to={`/project${slug}-details`}
              className="fw-600 fs-15 color-black"
            >
              READ CASE STUDY &nbsp;
              <FontAwesomeIcon icon={faArrowRightLong} />
            </Link>
          ) : (
            <></>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectListingCard;
