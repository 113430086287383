import React from "react";

import CapabilitiesHero from "../components/capabilities-hero";
import AboutCapabilities from "../components/about-capabilities";
import CapabilitiesDetails from "../components/capabilities-details";
import ProjectsSection from "../components/shared/projects-section";
import Footer from "../components/shared/footer";

const Capabilities = () => {
  return (
    <>
      <CapabilitiesHero />
      <AboutCapabilities />
      <CapabilitiesDetails />
      <ProjectsSection />
      <Footer />
    </>
  );
};

export default Capabilities;
