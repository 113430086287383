import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const ProjectCard = ({ image, type, location, description, slug }) => {
  return (
    <>
      <div className="item">
        <div className="mb-3">
          <img
            src={image}
            className="w-100"
            alt="Project"
            height="375px"
            style={{
              height: "372px !important",
              objectFit: "cover !important",
            }}
          />
        </div>
        <div>
          <span className="fs-14 fw-700">{type}</span>
          <p className="fs-20 fw-600 mb-2">{description}</p>
          {location ? (
            <p className="fw-600 fs-15 color-black">{location}</p>
          ) : (
            <Link
              to={`/project${slug}-details`}
              className="fw-600 fs-15 color-black"
            >
              FEATURED CASE STUDY &nbsp;
              <FontAwesomeIcon icon={faArrowRightLong} />
            </Link>
          )}
        </div>
      </div>
    </>
  );
};

export default ProjectCard;
