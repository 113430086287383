import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StatsCard = ({ number, description, icon }) => {
  return (
    <>
      <div className="col-md-6 mb-4">
        <div className="h-s4-card p-2">
          <div className="icon-div">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="h4-card-body">
            <span className="number">{number}</span>
            <p className="fw-600 fs-18">{description}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default StatsCard;
