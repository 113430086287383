import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/project3-1.png";
import ProjectImage2 from "../assets/images/project3-2.jpg";

const Project2ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp; General
                  maintenance activities which included replacement of fencing
                  and implementation of security cameras.
                </p>
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    KPI’s for the work included quality and timeframes.
                  </li>
                  <li className="fs-17">
                    Work needed to be concentrated into school holiday
                    timeframes.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">System</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    The KPI’s were entered into the Project Management system
                    and each individual worker was held accountable to deliver
                    on their individual KPI’s.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Fencing work conducted during school holiday periods.
                  </li>
                  <li className="fs-17">
                    ‘Working with children’ checks conducted.
                  </li>
                  <li className="fs-17">
                    Working with heights certification in place to fix leaky
                    roofs.
                  </li>
                  <li className="fs-17">
                    Safe Work Methods created in advance of each task and
                    project management system validates this was read by all
                    relevant workers.
                  </li>
                  <li className="fs-17">
                    Risks with powerlines near roof repair was managed through a
                    Safe Work Method.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Waste filtration system set up to capture run off from the
                    building process.
                  </li>
                  <li className="fs-17">
                    Compliance to the Department of Schools request for use of
                    sustainable products.
                  </li>
                  <li className="fs-17">Recycling of demolition parts.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project2ContentDetails;
