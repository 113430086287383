import React from "react";

import Project2DetailHero from "../components/project2-detail-hero";
import Project2ContentDetails from "../components/project2-content-details";
import Footer from "../components/shared/footer";

const Project1Details = () => {
  return (
    <>
      <Project2DetailHero />
      <Project2ContentDetails />
      <Footer />
    </>
  );
};

export default Project1Details;
