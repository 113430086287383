import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/pro3-1.jpg";

const Project3ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Individual Project Size</b> &nbsp;
                  $25,000 - $100,000
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Commencement</b> &nbsp; 2019
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp; Refurbishing of 48 units per annum for a region which included Kenthurst, Springwood, Liverpool, Orange and Young. The units were 1 to 3 bedroom and work activities include new kitchens, bathrooms, new carpet and paint. Elliam are Uniting Cares longest standing supplier for aged care refurbishment.
                </p>
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    3 weeks turnaround of a room and 4 weeks of apartments.
                  </li>
                  <li className="fs-17">
                    All units were turned around within the given time frame.
                  </li>
                  <li className="fs-17">
                    No critical defects in any of the 48 units, meaning no
                    delays to new owner occupancy.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">System</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Time management of all tasks to ensure new owners have
                    access on allocated days.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Managed dust health risks through mats and contained
                    movement of workers on the site.
                  </li>
                </ul>
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Work code to safeguard residential community of vulnerable
                    people.
                  </li>
                  <li className="fs-17">
                    Minimising intrusion on daily living by using back entries.
                  </li>
                  <li className="fs-17">
                    Noise pollution managed to minimise exposure within the
                    community.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project3ContentDetails;
