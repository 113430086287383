import React from "react";

import ProjectsSection from "./shared/projects-section";
import ProjectImage from "../assets/images/project-5.png";
import ProjectImage2 from "../assets/images/project4-2.jpg";

const Project4ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fs-20 fw-500">
                  <b className="fw-700">Total Contract Value</b> &nbsp;
                  $5,000,0000 annual project
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Individual Project Value</b> &nbsp;
                  $100,000
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Commencement</b> &nbsp; August
                  2021
                </p>
                <p className="fs-20 fw-500">
                  <b className="fw-700">Project Description</b> &nbsp;
                  Commercial office and residential refurbishment including
                  Caves Beach, Temora, South Coogee and Nurse stations at
                  Merrylands. Our systems allowed us to deliver quality at
                  scale, predicting issues and mitigating risks before they
                  become a problem.
                </p>
                <br />
                <br />
                <h2 className="fw-600">Performance</h2>
                <br />
                <h4 className="fw-600">Outcome</h4>
                <br />
                <ul>
                  <li className="fs-17">3 weeks turnaround.</li>
                  <li className="fs-17">
                    All units were turned around within the given time frame.
                  </li>
                  <li className="fs-17">
                    No critical defects in any of the 48 units that delayed new
                    owner occupancy.
                  </li>
                </ul>
                <br />
                <h4 className="fw-600">System</h4>
                <br />
                <ul>
                  <li className="fs-17">
                    Checklist attached to each job on the project management
                    system to ensure compliance.
                  </li>
                  <li className="fs-17">
                    Quality management reviews of details of every task and
                    feature using the checklist.
                  </li>
                  <li className="fs-17">
                    Accountability of workforce through documented record of
                    completed tasks.
                  </li>
                  <li className="fs-17">
                    Individual workers are held responsible for work quality and
                    timeliness.
                  </li>
                  <li className="fs-17">
                    Real time tracking of tasks and safety guidelines ensure
                    compliance reduces safety risks.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Safety</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Use of QR codes to monitor worker movement onsite.
                  </li>
                  <li className="fs-17">
                    Covid compliant reporting on worker locations and times
                    available to management and client 24/7.
                  </li>
                  <li className="fs-17">
                    Covid risk assessments signed and shared amongst the team.
                  </li>
                  <li className="fs-17">
                    Extra safeguards managing the risks of working within a
                    residential community such as masks and face shields, QR
                    code and testing, onsite lunches to reduce movement.
                  </li>
                  <li className="fs-17">
                    Added conditions of compliance to reduce interruptions of
                    daily living to the residential community eg: limited use of
                    car space when asked or other site specific requests.
                  </li>
                </ul>
                <br />
                <br />
                <img
                  src={ProjectImage2}
                  className="img-fluid w-100 mb-4"
                  alt="ProjectImage"
                />
                <br />
                <br />
                <h2 className="fw-600">Sustainability</h2>
                <br />
                <ul>
                  <li className="fs-17">
                    Separate rubbish loads to recycle waste.
                  </li>
                  <li className="fs-17">
                    Significant reduction in noise pollution through targeted
                    scheduling of activities and community liaison to minimise
                    disruption.
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <ProjectsSection />
    </>
  );
};

export default Project4ContentDetails;
