import React from "react";

import HeritageImage from "../assets/images/s3.png";

const CapabilitiesDetails = () => {
  return (
    <section className="cpb-s-3">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2 col-md-12">
              <h2 className="fw-600">Construction</h2>
              <p className="fw-400 fs-17 mb-4">
                Elliam has 25 years of construction experience, with projects
                ranging up to $5,000,000 in value.
                <br /> <br />
                Elliam’s sought after construction team works for councils
                across Greater Sydney including; City of Sydney, Liverpool,
                Willoughby and Blacktown. Our renovation and construction
                services range from community halls to public toilets, sporting
                facilities, Special Emergency Services, ovals, car park
                facilities and turf clubs.
                <br /> <br />
                Elliam’s custom designed project management system is tailored
                to meet the council’s exceptionally high standards for safety,
                quality and on-time construction. Because our project management
                system is based on transparency, it enables a council’s project
                management team to assess work via weekly detailed photography
                of completed tasks, removing the need for constant onsite
                inspections.
              </p>
              <h2 className="fw-600">Heritage</h2>
              <p className="fw-400 fs-17 mb-4">
                Elliam’s has 20 years of experience working with heritage
                buildings.
                <br />
                <br />
                Elliam has substantial ongoing heritage work; an average 25
                projects a year, project value ranging from $20,000 to $500,000,
                with a total of $2 million. Our heritage work includes matching
                historic windows, applying heritage building techniques and
                materials to roofs and specialised sandstone work.
              </p>
              <br />
              <img
                src={HeritageImage}
                className="img-fluid w-100 mb-4"
                alt=" HeritageImage"
              />
              <br />
              <br />
              <h2 className="fw-600">Refurbishment</h2>
              <p className="fw-400 fs-17 mb-4">
                Elliam has over 100 active refurbishment projects a year, and
                has completed over 2000 projects.
                <br />
                <br />
                Elliam has provided refurbishments services to the leading
                providers of aged care including the refurbishment of rooms and
                upgrading stations. Southern Cross, Uniting, Catholic Healthcare
                and Salvation Army are among Elliam’s key clients in aged care.
                <br />
                <br />
                Elliam’s success in the aged care provider market comes from our
                commitment to both the quality of our work and our respect for
                the person centred care values of aged care providers. Eilliam’s
                people are attuned to the needs of working within and alongside
                residential community. We understand and mitigate the risks to
                staff, visitors and residents, we also work tirelessly to have
                minimum impact on the quality of life of the community, pre,
                during and after our project is complete. Just by consistently
                getting this approach right, Elliam has developed a reputation
                that has led to rapid growth in the aged care sector.
                <br />
                <br />
                Our activities in aged care primarily involve the refurbishment
                of residential aged care rooms, Independent Living apartments
                and the upgrading of nurse stations.
              </p>
              <h2 className="fw-600">Fit out</h2>
              <p className="fw-400 fs-17 mb-4">
                Elliam has decades of experience in the fit outs of pubs and
                offices in Sydney and Wollongong, with projects ranging in size
                from $100,000 to $5,000,000. A successful fit out is one that
                can stand the test of time and high traffic.
                <br /> <br />
                Elliam’s fit-out quality is designed to endure. Due to the
                expertise of our team, Elliam has been successfully winning
                fit-out projects where both construction and fit out expertise
                are required. Much of Elliam’s fit out work is from repeat
                business with existing clients and referrals from past clients.
              </p>
              <h2 className="fw-600">Maintenance</h2>
              <p className="fw-400 fs-17 mb-4">
                Elliam has a dedicated maintenance team who have delivered
                ongoing maintenance services to both the Department of Housing
                and Department of Education, throughout Greater Sydney and
                Wollongong areas.
                <br />
                <br />
                Elliam’s extensive maintenance work includes thousands of
                projects, averaging 5 a day, completing 2 bathrooms and 1
                kitchen a week for the Department of Housing.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CapabilitiesDetails;
