import React from "react";

import ContactHero from "../components/contact-hero";
import Footer from "../components/shared/footer";

const ContactUs = () => {
  return (
    <>
      <ContactHero />
      <Footer />
    </>
  );
};

export default ContactUs;
