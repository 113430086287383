import React from "react";

import data from "../content/news.json";

import NewsCard from "./shared/news-card";

const NewsListing = () => {
  return (
    <section className="news-s-2">
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            {data.news.map((item) => {
              return (
                <NewsCard
                  key={item.id}
                  date={item.date}
                  title={item.title}
                  slug={item.slug}
                />
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewsListing;
