import React from "react";

import { Link } from "react-router-dom";

import logo from "../../assets/images/logo-white.svg";

const Footer = () => {
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-content pt-5 pb-5">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-3 mb-50 mobile-text-center">
              <div className="footer-widget">
                <div className="footer-logo">
                  <Link to="/">
                    <img src={logo} className="img-fluid" alt="Elliam Logo" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 offset-md-2 mb-30 mobile-text-center">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Contact Us</h3>
                </div>
                <div className="footer-text mb-25">
                  <p className="text-white fs-18 fw-500">
                    <a
                      href="tel:+612 8888 88888"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      0408 663 275
                    </a>
                    <br />
                    <a
                      href="mailto:admin@elliam.com.au"
                      className="text-white"
                      style={{ textDecoration: "none" }}
                    >
                      admin@elliam.com.au
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-3 col-md-3 mb-30 mobile-text-center">
              <div className="footer-widget">
                <div className="footer-widget-heading">
                  <h3>Address</h3>
                </div>
                <div className="footer-text mb-25">
                  <p className="text-white fs-18 fw-500">
                    169 Bellevue Parade, Carlton,
                    <br /> NSW, 2218
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright-area">
        <div className="container">
          <div className="row">
            <div className="col-xl-7 col-lg-7 d-lg-block footer-link-center text-left mobile-d-none">
              <div className="footer-menu">
                <ul className="px-0 footer-ul">
                  <li className="mx-0">
                    <Link to="/about" className="footer-links mr-2">
                      ABOUT
                    </Link>
                  </li>
                  <li>
                    <Link to="/projects" className="footer-links mx-2">
                      PROJECTS
                    </Link>
                  </li>
                  <li>
                    <Link to="/capabilities" className="footer-links mx-2">
                      CAPABILITIES
                    </Link>
                  </li>
                  <li>
                    <Link to="/news" className="footer-links mx-2">
                      NEWS
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-5 col-lg-5 mobile-text-center">
              <div className="copyright-text">
                <p className="text-white fs-18">
                  All rights reserved &copy; 2022 Elliam Pty Ltd{" "}
                  <a href="test.html" className="color-dark-blue">
                    Terms and Conditions
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
