import React from "react";

import Header from "./shared/header";

const ContactHero = () => {
  return (
    <div className="main-breadcrum bg-black mb-3">
      <div className="container-fluid no-px-tab">
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row pt-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">Contact us</span>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    Questions? Let’s talk
                  </h1>
                </div>
                <div className="col-md-12 mb-5">
                  <p className="text-white fs-18 fw-400">
                    Contact one of our friendly staff Monday to Friday, 9:00am –
                    5:00pm
                  </p>
                </div>
              </div>
              <div className="row pb-5">
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="pt-4 px-3 pb-3 bg-white">
                    <h2 className="fw-600 fs-45 mb-2">Call</h2>
                    <a
                      href="tel: 0408 663 275"
                      className="fw-500 fs-20 color-dark-blue"
                    >
                      0408 663 275
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 mb-4">
                  <div className="pt-4 px-3 pb-3 bg-white">
                    <h2 className="fw-600 fs-45 mb-2">Email</h2>
                    <a
                      href="mailto:admin@elliam.com.au"
                      className="fw-500 fs-20 color-dark-blue"
                    >
                      admin@elliam.com.au
                    </a>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 offset-md-3 offset-lg-0 mb-4">
                  <div className="pt-4 px-3 pb-3 bg-white">
                    <h2 className="fw-600 fs-45 mb-2">Address</h2>
                    <div className="fw-500 fs-20 color-dark-blue">
                      169 Bellevue Parade, Carlton, NSW, 2218
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ContactHero;
