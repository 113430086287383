import React from "react";
import { Link } from "react-router-dom";

import Header from "./shared/header";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong } from "@fortawesome/free-solid-svg-icons";

const Hero = () => {
  return (
    <>
      <div className="banner-bg home-banner">
        <div
          className="container-fluid no-px-tab"
          style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
        >
          <Header />

          <section className="home-s-1">
            <div className="container-fluid">
              <div className="container">
                <div className="row d-flex align-items-end">
                  <div className="col-md-6 py-5">
                    <h1 className="home-s1-h1 text-white fw-700 py-5">
                      Builds, fitouts and refurbishments.
                    </h1>
                  </div>

                  <div className="col-lg-4 col-md-5 offset-md-1 offset-lg-2">
                    <div className="banner-r-right-card bg-dark-blue py-3 px-4">
                      <p className="text-white mb-1">News</p>
                      <p
                        className="text-white mb-2 fs-20 fw-600"
                        style={{ lineHeight: "24px" }}
                      >
                        Elliam is recgnised as a leader in hertiage
                        refurbishment. Our expert teams are masters at blending
                        new building techniques with old ...
                      </p>
                      <Link
                        to="/news-02"
                        className="text-white mb-0 fw-600 text-decoration-none"
                      >
                        Read More &nbsp;
                        <FontAwesomeIcon icon={faArrowRightLong} />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </>
  );
};

export default Hero;
