import React from "react";

import data from "../content/news.json";

import Header from "./shared/header";

const News5DetailHero = () => {
  return (
    <div className="main-breadcrum bg-black1">
      <div
        className="container-fluid no-px-tab"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.3)" }}
      >
        <Header />
        <section className="main-breadcrum-section">
          <div className="container-fluid">
            <div className="container">
              <div className="row py-5">
                <div className="col-md-12 pt-5">
                  <span className="text-white fs-16 fw-500">
                    {data.news[5].date}
                  </span>
                </div>
                <div className="col-md-12 mb-3">
                  <h1 className="home-s1-h1 color-dark-blue fw-700">
                    {data.news[5].title}
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default News5DetailHero;
