import data from "../content/news.json";
import NewsCard from "./shared/news-card";
import NewsImage from "../assets/images/news7-01.webp";
import NewsImage2 from "../assets/images/news7-02.webp";

const News6ContentDetails = () => {
  return (
    <>
      <section className="cpb-s-3">
        <div className="container-fluid">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 offset-lg-2 col-md-12">
                <p className="fw-400 fs-17 mb-4">
                Managing hazards on construction sites requires a comprehensive approach that prioritises safety and compliance with regulations, including those outlined by SafeWork NSW.  
                </p>
                 <p className="fw-400 fs-17 mb-4">
                 Elliam has extensive experience working in over 200 sites across New South Wales, spanning two decades. These sites include working within the living spaces of vulnerable communities such as social housing, schools and residential aged care. It also includes the exacting safety management of progressing construction activities within community sites while they remain operational, such as hotels and libraries.</p>
                 <p className="fw-400 fs-17 mb-4">Elliam has managed the full range of hazards while ensuring a strong safety record in the areas of; traffic management, pedestrian safety, falling objects, noise and vibration, dust and air quality, chemical exposure, excavation and trenching, electrical safety, structural stability and emergency preparedness. </p>
                 <br />
                <h4 className="fw-600">Traffic Management</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">During the reconstruction of the verandah and roof of Brighton Hotel, Elliam were required to restrict traffic flow of Brighton Le Sands main street, while construction materials and demolition materials entered and existed the site. Traffic management plans were implemented in accordance with SafeWork NSW guidelines including temporary road closure, proper signage, barriers, and designated pedestrian walkways, to mitigate risks to workers and pedestrians.</p>
                <br/>
                <img
                  src={NewsImage}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage1"
                /> 
                 <br />           
                <p className="fw-400 fs-17 mb-4">Elliam works alongside and within vulnerable communities </p>
                <br/>
                <h4 className="fw-600">Pedestrian Safety</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">Merryland’s library attracts a diverse range of ages including very young children. During construction, Elliam ensured clear pathways for pedestrians of all ages while maintaining safe access to adjacent properties, as per SafeWork NSW requirements. Elliam’s Project Managers regularly inspected and addressed any potential trip and fall hazards that could be caused by construction activities.</p>
                <br/>
                <h4 className="fw-600">Falling Objects</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Elliam’s Project Managers conduct regular inspections of overhead work areas to identify and mitigate potential hazards. On sites such as Catholic Healthcare’s Residential Aged Care in Kogarah, Elliam were able to implement measures such as debris netting, toe boards, and barricades as recommended by SafeWork NSW, to prevent objects from falling outside the work area.
                </p>
                <br/>
                <h4 className="fw-600">Noise and Vibration </h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Manly Pacific Hotel was able to maintain optimum occupancy as Elliam worked on the pool and rooftop refurbishment.  Noise levels were successfully contained to minimise interruptions to guest experience. Eliiam’s team were able to monitor noise levels regularly and adjust construction methods and hours of work, as required, to maintain compliance with local noise ordinances and guest expectations.
                </p>
                <br/>
                <h4 className="fw-600">Dust and Air Quality </h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Elliam stringently follow SafeWork NSW guidelines for dust control measures, including water spraying, dust barriers, and the use of low-emission equipment. While working on Fire remediation in Southern Cross Care, Cootamundra, Elliam repaired fire damaged area including fire and soot damage, while containing the flow of dust to surrounding area and rooms. In line with our standard practice, Elliam conducted air quality monitoring to ensure compliance with regulatory standards and to protect nearby residents from health risks.  
                </p>
                <br />
                <br/>
                <img
                  src={NewsImage2}
                  className="img-fluid w-100 mb-4"
                  alt="NewsImage2"
                />
                <br />
                <p className="fw-400 fs-17 mb-4">Elliam has worked on over 200 heritage buildings in New South Wales</p>
                <br/>
                <h4 className="fw-600">Chemical Exposure</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                Elliam has worked on over 200 heritage refurbishment sites in New South Wales and regularly manages hazardous chemicals such as the removal of lead paint, commonly found in buildings dating back to the 1970’s. Elliam recently removed lead paint from a heritage building as part of a refurbishment for Willoughby Council. Elliam comply to SafeWork NSW recommendations for proper handling, storage, and disposal of hazardous chemicals used in construction. All Elliam employees have the appropriate training and personal protective equipment to minimize the risk of chemical exposure. </p>
                <br/>
                <h4 className="fw-600">Excavation and Trenching</h4>
                <br />
                <p className="fw-400 fs-17 mb-4">
                When Elliam concreted the driveway for Southern Cross Care, it involved excavation, grading and drainage. To maintain safety standards, our team conducted regular inspections of excavation sites and maintained clear communication with utility providers. In the construction process, Elliam adhered to SafeWork NSW excavation safety protocols, including shoring, trench boxes, and utility locates, to prevent cave-ins and underground utility strikes.</p>
                <br/>
                <h4 className="fw-600">Electrical Safety</h4>
                <br/>
                <p className="fw-400 fs-17 mb-4">
                Commercial kitchen replacement at Sothern Cross Care in Goulburn involved the upgrading of extensive electrical wiring to enable a fully functional kitchen. Elliam’s electrical work was in compliance with SafeWork NSW electrical safety standards, including proper grounding, insulation, and the use of qualified electricians. On all Elliam’s construction and refurbishment activities, our Project Managers conduct regular inspections of electrical equipment and wiring to identify and address potential hazards.</p>
                <br/>
                <h4 className="fw-600">Structural Stability</h4>
                <br/>
                <p className="fw-400 fs-17 mb-4">
                Nearly a decade of work with the Department of Housing in refurbishment and maintenance activities has involved ensuring the structural safety of the buildings for both resident and worker safety.  Elliam carefully follow SafeWork NSW guidelines for structural stability assessments, including regular inspections and monitoring by qualified engineers. Our Project Managers address any concerns promptly to ensure the safety of workers and the surrounding community and escalate more serious concerns to the Department of Housing. </p>
                <br/>
                <h4 className="fw-600">Emergency Preparedness</h4>
                <br/>
                <p className="fw-400 fs-17 mb-4">
                Worker and community safety and well-being is front and centre of all Elliam’s work practices. Emergencies are best prevented but when they do occur, Elliam is prepared with robust procedures to address the crisis such as First Aid, Fire Equipment and Emergency evacuation. We have developed and implemented comprehensive emergency response plans in accordance with SafeWork NSW requirements. Our Project Managers conduct regular drills and provide first aid training to workers to ensure preparedness for incidents such as fires, medical emergencies, and natural disasters.</p>
                <br/>
                
                <p className="fw-400 fs-17 mb-4">Elliam’s strong track record in construction safety is evident in our excellent compliance history with SafeWork NSW regulations. Our long history working with some of New South Wales most vulnerable communities in Aged Care, Schools and Council facilities is a reflection of the trust we have fostered after decades of incident free hazard management.</p>
                <br/>
                <p className="fw-400 fs-17 mb-4">To find out more on Elliam’s safety hazard management contact us on <a href="mailto:admin@elliam.com.au">admin@elliam.com.au</a></p>
              </div>
            </div>
          </div>
        </div>

      </section>
      <section className="news-s-2">
        <div className="container-fluid">
          <div className="container">
            <div className="row mb-3">
              <div className="col-12">
                <h2 className="fs-40 fw-600 color-dark-blue">Other news</h2>
              </div>
            </div>
            <div className="row">
              {data.news.slice(0, 5).map((item) => {
                return (
                  <NewsCard
                    key={item.id}
                    date={item.date}
                    title={item.title}
                    slug={item.slug}
                 
                  />
                );
              })}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default News6ContentDetails;
